// 
.partners1 {
    background: linear-gradient(to bottom right, #0a172d, #121416);

    height: 100vh;
    width: 100vw;

    position: relative;

    &__BG1 {
        height: 100vh;
        width: 100vw;
        position: absolute;

        background: url(../../../../images/partnersSection1BG1_1920.jpg) center no-repeat;
        background-size: cover;
    }
}

//1024 * 1919
@media (max-width: 1919px) {}

//768 * 1023
@media(max-width: 1023px) {
    .partners1 {
        &__BG1 {
            // height: 100vh;
            // width: 100vw;
            // position: absolute;
    
            background: url(../../../../images/partnersSection1BG1_768.jpg) center no-repeat;
            background-size: cover;
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .partners1 {
        &__BG1 {
            // height: 100vh;
            // width: 100vw;
            // position: absolute;
    
            background: url(../../../../images/partnersSection1BG1_768.jpg) right no-repeat;
            background-size: cover;
        }
    }
}