// 
.burger {
    z-index: 1000;

    cursor: pointer;

    position: fixed;
    right: 45px;

    height: 40px;
    width: 40px;

    transition: top 0.2s linear 0s;

    &_active{
        top: 78px;
    }
    &_passive{
        top: -30px;
    }

    &__lines {
        height: 100%;

        display: flex;
        justify-content: center;
        flex-direction: column;

        span {
            position: absolute;
            display: block;
            width: 100%;
            height: 2px;
            background-color: #A0A8B3;

            transition: top 0.2s ease 0.1s, bottom 0.2s ease 0.1s, transform 0.1s ease 0s;

            &:nth-child(1) {
                top: 0px;
                margin-top: 13px;
            }

            &:nth-child(1) {
                // top: 6px;
            }

            &:nth-child(3) {
                bottom: 0px;
                margin-bottom: 13px;
            }
        }

        //нажатие в крест
        &_active {
            span {
                transition: top 0.2s ease 0s, bottom 0.2s ease 0s, transform 0.1s ease 0.2s;

                &:nth-child(1) {
                    top: 6px;
                    transform-origin: 50% 50%;
                    transform: rotate(45deg);
                }

                &:nth-child(2) {
                    opacity: 0;
                }

                &:nth-child(3) {
                    bottom: 6px;
                    transform-origin: 50% 50%;
                    transform: rotate(-45deg);
                }
            }
        }
    }

    &__linksopen {
        background-color: aqua;
        background:
            linear-gradient(0deg,
                rgba(22, 22, 22, 0.3),
                rgba(22, 22, 22, 0.3)),
            linear-gradient(238.17deg,
                rgba(12, 18, 26, 0.6) 37.87%,
                rgba(7, 21, 43, 0.7) 59.92%,
                rgba(6, 23, 51, 0.9) 80.92%,
                #021533 91.44%),
            black;
        background-blend-mode: color, normal;

        position: fixed;

        left: 0;
        top: -200vh;

        width: 100%;
        height: 100%;

        transition: top 0.3s linear;

        &_active {
            top: 0;
        }
    }

    &__linkswrapper {
        position: absolute;
        top: 200px;
        left: 509px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        a {
            text-decoration: none;

            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 58px;
            line-height: 160%;
            font-feature-settings: 'pnum' on, 'lnum' on;

            // color: #A0A8B3;
            transition: color 1s linear;

            &:hover {
                transition: color 0.3s linear;
                color: white;
            }
        }

        &_active {
            color: white;
        }

        &_passive {
            color: #A0A8B3;
        }
    }

    &__logo {
         z-index: 1;
        position: relative;
        left: 150px;
        top: 40px;

        height: 56px;
        width: 180px;

        display: flex;
        align-items: center;
        justify-content: left;

        a{
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        &_white {
            position: absolute;
            height: 100%;
            width: auto;
        }

        &_blue {
            position: absolute;
            height: 100%;
            width: auto;
        }
    }
}

//1024 * 1919
@media (max-width: 1919px) {
    .burger {
        // z-index: 1000;

        // cursor: pointer;

        // position: fixed;
        // right: 45px;

        // height: 14px;
        // width: 40px;

        &_active{
            top: 46px;
        }

        &__openlinks {
            background:
                linear-gradient(238.17deg,
                    rgba(12, 18, 26, 0.6) 37.87%,
                    rgba(7, 21, 43, 0.7) 59.92%,
                    rgba(6, 23, 51, 0.9) 80.92%,
                    #021533 91.44%);
        }

        &__linkswrapper {
            top: 127px;
            left: 245px;

            a {
                font-size: 38px;
            }
        }

        &__logo {
            left: 50px;
            top: 46px;

            height: 39px;
            width: 120px;
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .burger {
        // z-index: 1000;

        // cursor: pointer;

        // position: fixed;
        right: 50px;

        // height: 14px;
        // width: 40px;

        &_active{
            // top: 35px;
        }

        &__linkswrapper {
            top: 256px;
            left: 166px;

            a {
                font-size: 33px;
            }
        }

        &__logo {
            // left: 50px;
            // top: 46px;

            // height: 39px;
            width: 50px;
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .burger {
        // z-index: 1000;

        // cursor: pointer;

        // position: fixed;
        right: 31px;

        // height: 14px;
        // width: 40px;

        &_active{
            top: 36px;
        }

        &__linkswrapper {
            top: 162px;
            left: 32px;
        }

        &__logo {
            left: 32px;
            top: 36px;
        }
    }
}