// 
.company6 {
    height: 456px;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #F8F8F8;

    &__page {
        max-width: 1200px;
        flex: 1 0 100%;

        display: flex;
    }

    &__text {
        flex: 1 0 50%;

        p {
            margin-right: 130px;
            padding-bottom: 45px;
            border-bottom: solid 1px;
            border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 100;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 180%;
            letter-spacing: 0.01em;
            color: #38414D;
        }
    }

    &__header {
        flex: 1 0 50%;

        display: flex;
        flex-direction: column;

    }

    &__heading {
        width: 500px;
        h3 {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 44px;
            line-height: 135%;
            letter-spacing: 0.02em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            color: #0F2538;
        }
    }

    &__link {
        align-self: flex-end;

        padding-top: 56px;
        padding-right: 50px;
    }
}

//1700 * 1919
@media (max-width: 1919px) {
    .company6 {
        &__heading {
            width: 450px;
            h3 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 40px;
                // line-height: 135%;
                // letter-spacing: 0.02em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__link {
            // align-self: flex-end;
    
            padding-top: 68px;
            // padding-right: 50px;
        }
    }
}

//1280 * 1699
@media (max-width: 1699px) {
    .company6 {
        &__page {
            max-width: 940px;
            // flex: 1 0 100%;

            // display: flex;
        }

        &__text {
            // flex: 1 0 50%;

            p {
                margin-right: 80px;
                // padding-bottom: 45px;
                // border-bottom: solid 1px;
                // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 100;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 16px;
                // line-height: 180%;
                // letter-spacing: 0.01em;
                // color: #38414D;
            }
        }

        &__heading {
            width: 400px;
            h3 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 33px;
                // line-height: 135%;
                // letter-spacing: 0.02em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__link {
            // align-self: flex-end;

            padding-top: 85px;
            // padding-right: 50px;
        }
    }
}

//1024 * 1279
@media (max-width: 1279px) {
    .company6 {
        &__heading {
            width: 350px;

            h3 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 29px;
                // line-height: 135%;
                // letter-spacing: 0.02em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__link {
            // align-self: flex-end;

            padding-top: 95px;
            // padding-right: 50px;
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .company6 {
        height: 580px;

        &__page {
            max-width: 668px;
            // flex: 1 0 100%;

            // display: flex;
            flex-direction: column-reverse;
        }

        &__text {
            // flex: 1 0 50%;

            p {
                width: 500px;
                margin-right: 0px;
                padding-top: 58px;
                padding-bottom: 40px;
                // border-bottom: solid 1px;
                // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 100;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 15px;
                // line-height: 180%;
                // letter-spacing: 0.01em;
                // color: #38414D;
            }
        }

        &__heading {
            width: 270px;

            h3 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 24px;
                // line-height: 135%;
                // letter-spacing: 0.02em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__link {
            // align-self: flex-end;

            padding-top: 47px;
            padding-right: 230px;
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .company6 {
        height: 540px;

        &__page {
            // max-width: 256px;
            width: 100%;
            padding-left: 32px;
            padding-right: 32px;
            // flex: 1 0 100%;

            // display: flex;
            // flex-direction: column-reverse;
        }

        &__text {
            // flex: 1 0 50%;

            p {
                width: 100%;
                // margin-right: 0px;
                // padding-top: 58px;
                padding-bottom: 32px;
                // border-bottom: solid 1px;
                // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 100;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 14px;
                // line-height: 180%;
                // letter-spacing: 0.01em;
                // color: #38414D;
            }
        }

        &__heading {
            width: 100%;

            h3 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 22px;
                // line-height: 135%;
                // letter-spacing: 0.02em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__link {
            // align-self: flex-end;

            padding-top: 34px;
            padding-right: 0px;
        }
    }
}