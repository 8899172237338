@font-face {
    font-family: 'OpiumNewWebNormal';
    font-family: 'OpiumNewC';
    src:
        url('fonts/Opium-New_Normal.woff2') format('woff2'),
        url('fonts/Opium-New_Normal.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpiumNewWebItalic';
    font-family: 'OpiumNewCItalic';
    src:
        url('fonts/Opium-New_Italic.woff2') format('woff2'),
        url('fonts/Opium-New_Italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

*,
*:before,
*:after {
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Noto Sans', sans-serif;
}