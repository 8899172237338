// 
.company1 {
    background: linear-gradient(to bottom right, #0a172d, #121416);

    height: 100vh;
    width: 100vw;

    position: relative;

    // overflow-x: none;
    // scrollbar-width: none;

    &__BG1 {
        height: 100%;
        width: 100%;
        position: absolute;

        background: url(../../../../images/companySection1BG1_1920.jpg) center no-repeat;
        background-size: cover;
    }
}

//1024 * 1919
@media (max-width: 1919px) {}

//768 * 1023
@media(max-width: 1023px) {
    .company1 {
        &__BG1 {
            // height: 100%;
            // width: 100%;
            // position: absolute;
    
            background: url(../../../../images/companySection1BG1_768.jpg) center no-repeat;
            background-size: cover;
        }
    }
}

//320 * 767
@media (max-width: 767px) {}