// 
.vacancy5 {
    display: flex;
    justify-content: center;

    &__page {
        max-width: 1200px;
        flex: 1 0 100%;

        padding: 140px 0;

        display: flex;
        flex-direction: column;
    }

    &__heading {
        h3 {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 44px;
            line-height: 135%;
            letter-spacing: 0.02em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            color: #0F2538;
        }
    }

    &__gallery {
        width: 100%;

        padding-top: 80px;

        overflow: hidden;

        cursor: pointer;
    }

    &__gallery2 {
        display: none;
    }

    &__gallery3 {
        width: 256px;
    }
}

//1024 * 1919
@media (max-width: 1199px) {
    .vacancy5 {
        &__page {
            max-width: 924px;
            // flex: 1 0 100%;

            // padding: 140px 0;

            // display: flex;
            // flex-direction: column;
        }

        &__heading {
            h3 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 29px;
                // line-height: 135%;
                // letter-spacing: 0.02em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .vacancy5 {
        &__page {
            max-width: 668px;
            // flex: 1 0 100%;

            padding: 120px 0;

            // display: flex;
            // flex-direction: column;
        }

        &__gallery {
            // width: 100%;

            padding-top: 60px;
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .vacancy5 {
        &__page {
            // max-width: 256px;
            // flex: 1 0 100%;

            padding: 80px 0 30px 0;

            max-width: 100%;
            padding-left: 32px;
            padding-right: 32px;

            // display: flex;
            // flex-direction: column;
        }

        &__gallery {
            display: none;
        }

        &__gallery2 {
            display: flex;
            justify-content: center;

            padding-top: 48px;
        }
    }
}