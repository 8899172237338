.section2 {
    background-color: white;

    &__content {
        z-index: 1;
        position: relative;
        width: 570px;
        top: 211px;
        left: 390px;

        h2 {
            position: relative;

            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 44px;
            line-height: 135%;
            letter-spacing: 0.02em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;

            padding-bottom: 40px;

            color: #0F2538;
        }

        p {
            position: relative;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 180%;
            letter-spacing: 0.01em;

            padding-bottom: 20px;

            color: #38414D;
        }
    }

    &__BG {
        /*обертка*/
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        right: 0;
    }

    &__BG1 {
        /*город*/
        position: absolute;
        height: 100%;
        width: 50%;
        top: 0;
        right: 0;
        background: url(../../../../images/section2BG1.jpg) right no-repeat;
        background-size: contain;
        backface-visibility: hidden;
        transition: none;
        transform: perspective(200px) translateZ(15px);
        transform-origin: 0 50%;

        &--enter {
            transition: transform 3s ease-out;
            transform: perspective(200px) translateZ(0px);
        }
    }

    &__BG2 {
        /*уголок*/
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        right: 350px;

        background: url(../../../../images/section2BG2.svg) right no-repeat;
        background-size: contain;
    }
}


@media (max-width: 1599px) {
    .section2 {

        &__BG {
            // position: absolute;
            // height: 100%;
            // width: 100%;
            // top: 0;
            // right: -250px;
        }

        &__BG1 {
            // right: 100px;
        }
    }
}

@media (max-width: 1399px) {
    .section2 {

        &__BG {
            // position: absolute;
            // height: 100%;
            // width: 100%;
            // top: 0;
            right: -200px;
        }

        &__BG1 {
            right: 70px;
        }
    }
}

//1024 * 1919
// @media (max-width: 1919px) {
@media (max-width: 1199px) {
    .section2 {

        //min-height: 653px;

        &__content {
            width: 384px;
            top: 140px;
            left: 180px;

            h2 {
                font-size: 29px;
            }
        }

        &__BG {
            // position: absolute;
            // height: 100%;
            // width: 100%;
            // top: 0;
            right: -200px;
        }
        &__BG1 {
            right: 150px;
        }

        &__BG2 {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            right: 400px;

            background: url(../../../../images/section2BG2.svg) right no-repeat;
            background-size: contain;
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .section2 {

        //min-height: 876px;

        &__content {
            top: 305px;
            left: 166px;

            h2 {
                font-size: 25px;
            }

            p {
                font-size: 15px;
            }
        }

        &__BG {
            display: none;
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .section2 {
        display: flex;
        justify-content: center;

        &__content {
            // width: 256px;
            width: auto;
            padding-left: 32px;
            padding-right: 32px;

            position: static;

            padding-top: 166px;

            h2 {
                font-size: 20px;
            }

            p {
                font-size: 14px;
            }
        }
    }
}
