.contacts4 {
    background-color: white;

    &__page {
        width: 1200px;

        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }

    &__formblock {
        display: flex;
        justify-content: flex-start;
    }

    &__heading {
        min-width: 410px;
        padding-top: 280px;
    }

    h2 {
        width: 270px;

        font-family: 'OpiumNewC';
        font-style: normal;
        font-weight: 400;
        font-size: 44px;
        line-height: 135%;
        letter-spacing: 0.02em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
        color: #0F2538;
    }

    &__content {
        padding-top: 280px;
        padding-bottom: 40px;
        margin-bottom: 80px;
        border-bottom: 2px solid black;
        border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 1;

        font-family: 'OpiumNewC';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 160%;
        letter-spacing: 0.03em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
        color: #38414D;
    }

    &__form {
        padding-bottom: 134px;

        input {
            width: 100%;
            height: 44px;

            // border-bottom: 1px solid black;
            // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 47% 0%;
            margin: 29px 0;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #38414D;

            &:focus {
                outline: none;
            }

            &::placeholder {
                color: #A0A8B3;
            }
        }

        &_lineblock1 {
            display: flex;
            justify-content: flex-start;

            width: 100%;

            div:nth-child(1) {
                margin-right: 5px;
            }

            div {
                width: 50%;
            }
        }

        &_active {
            border-bottom: solid 1px red;
            border-image: linear-gradient(to right, rgba(255, 0, 0, 0.4), rgba(255, 0, 0, 0.05)) 1;
        }

        &_passive {
            // border: none;
            border-bottom: 1px solid black;
            border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 1;
        }

        &_activetext {
            color: red;
        }

        &_passivetext {
            color: #A0A8B3;
        }
    }

    &__formplaceholder {
        position: relative;

        input {}

        label {
            position: absolute;
            top: 0px;
            left: 0;
            height: 100%;
            width: 100%;

            // color: #A0A8B3;

            display: flex;
            align-items: center;

            pointer-events: none;

            transition: 0.2s linear;
        }

        input:focus+label,
        input:not(:placeholder-shown)+label {
            top: -35px;
            left: 0px;
            font-size: 13px;

            // color: #38414D;
        }
    }

    &__formsubmit {
        padding-top: 30px;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__formprivacy {
        width: 430px;

        p {
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: #A0A8B3;

            opacity: 0.7;
        }

        a {
            color: #A0A8B3;
        }
    }

    &__button {
        align-self: flex-start;
        position: relative;
        top: -10px;


        display: flex;
        align-items: center;

        background-color: white;

        width: 250px;
        height: 40px;

        cursor: pointer;

        display: flex;
        justify-content: flex-end;

        span {
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 130%;
            color: #A0A8B3;
        }

        img {
            padding-right: 20px;
        }

        &:hover {
            color: #676c74;
        }
    }

    &__response-error {
        color: #f00;
        padding: 10px 0;
    }
}

//1024 * 1919
@media (max-width: 1199px) {
    .contacts4 {
        &__page {
            max-width: 915px;
        }

        &__heading {
            min-width: 320px;
        }

        h2 {
            font-size: 29px;
        }

        &__content {
            font-size: 18px;
            max-width: 606px;
        }

        &__form {
            max-width: 606px;
        }

        &__formprivacy {
            width: 310px;

            p {
                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 14px;
                // line-height: 95%;
                // color: #A0A8B3;

                // opacity: 0.7;
            }

            a {
                // color: #A0A8B3;
            }
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .contacts4 {
        &__page {
            width: 100%;
            padding: 0% 50px;
        }

        &__heading {
            padding-top: 230px;
            padding-bottom: 32px;
        }

        &__content {
            //font-size: 18px;
            //max-width: 606px;
            padding-top: 48px;
        }

        &__formblock {
            flex-direction: column;
        }

        &__form {
            max-width: 100%;

            padding-bottom: 120px;
        }

        &__formprivacy {
            width: 297px;

            p {
                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 14px;
                // line-height: 95%;
                // color: #A0A8B3;

                // opacity: 0.7;
            }

            a {
                // color: #A0A8B3;
            }
        }

        &__button {
            // align-self: flex-start;
            // position: relative;
            // top: -10px;


            // display: flex;
            // align-items: center;

            // background-color: white;

            // width: 250px;
            // height: 40px;

            // cursor: pointer;

            // display: flex;
            // justify-content: flex-end;

            span {
                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 18px;
                // line-height: 130%;
                // color: #A0A8B3;
            }

            img {
                // padding-right: 20px;
            }

            &:hover {
                // color: #676c74;
            }
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .contacts4 {
        &__page {
            width: 100%;
            padding: 0px;
        }

        &__heading {
            //min-width: 320px;
            padding-top: 150px;
            padding-bottom: 0px;
        }

        h2 {
            font-size: 25px;
            padding: 0 32px;
            margin-right: 0;

            min-width: 320px;
        }

        &__content {
            margin: 0 32px;
            font-size: 16px;
            padding-top: 48px;
            padding-bottom: 32px;
        }

        &__formblock {
            flex-direction: column;
        }

        &__form {
            padding: 0 32px;
            padding-bottom: 70px;
            padding-top: 50px;

            &_lineblock1 {
                flex-direction: column;


            }

            &_lineblock1 {
                // display: flex;
                // justify-content: flex-start;
                flex-direction: column;

                // width: 100%;

                div:nth-child(1) {
                    // margin-right: 5px;
                }

                div {
                    width: 100%;
                }
            }
        }

        &__formsubmit {
            // padding-top: 30px;

            // display: flex;
            // justify-content: space-between;
            // align-items: center;

            flex-direction: column;
        }

        &__formprivacy {
            // width: 256px;
            width: auto;
            align-self: flex-start;

            p {
                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 14px;
                // line-height: 95%;
                // color: #A0A8B3;

                // opacity: 0.7;
            }

            a {
                // color: #A0A8B3;
            }
        }

        &__button {
            margin-top: 30px;
            // align-self: flex-start;
            // position: relative;
            top: 0px;


            // display: flex;
            // align-items: center;

            // background-color: white;

            // width: 250px;
            // height: 40px;

            // cursor: pointer;

            // display: flex;
            justify-content: flex-end;

            span {
                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 18px;
                // line-height: 130%;
                // color: #A0A8B3;
            }

            img {
                // padding-right: 20px;
                display: block;
                width: 100px;
            }

            &:hover {
                // color: #676c74;
            }
        }

    }
}

@media (max-width: 335px) {
    .contacts4 {
        &__capcha {
            position: relative;
            left: -18px;
        }
    }
}
