//
.recommend3 {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__page {
        max-width: 1320px;
        flex: 1 0 100%;

        padding-top: 80px;
        padding-bottom: 224px;
    }

    &__licenses {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    &__licens {
        padding: 80px 20px 0px;
    }
}

@media (max-width: 1919px) {
    .recommend3 {
        // display: flex;
        // flex-direction: column;
        // align-items: center;

        &__page {
            width: 1200px;
            // flex: 1 0 100%;

            // padding-top: 80px;
            // padding-bottom: 195px;
        }
    }
}

//768 * 1919
@media (max-width: 1199px) {
    .recommend3 {
        // display: flex;
        // flex-direction: column;
        // align-items: center;

        &__page {
            width: 698px;
            // flex: 1 0 100%;

            // padding-top: 80px;
            padding-bottom: 195px;
        }

        &__licens {
            padding: 60px 15px 0px;
        }
    }
}

//768 * 1023
// @media(max-width: 1023px) {}

//320 * 767
@media (max-width: 767px) {
    .recommend3 {
        // display: flex;
        // flex-direction: column;
        // align-items: center;

        &__page {
            width: 320px;
            // flex: 1 0 100%;

            padding-top: 40px;
            padding-bottom: 155px;
        }

        &__licens {
            padding: 60px 32px 0px;
        }
    }
}