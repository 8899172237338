.section3 {

    &__BG1-wrap {
        position: absolute;
        width: 100%;
        height: 100%;
        will-change: transform;
    }

    &__BG1 {
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
        transition: transform 0s, opacity .3s ease-out;
        transform: perspective(200px) translateZ(24px);
        opacity: 0;

        &--enter {
            transition: transform 3s ease-out, opacity 3s ease-out;
            transform: perspective(200px) translateZ(0px);
            opacity: 1;
        }

        &--leave {
            transition: transform 3s ease-out, opacity .3s ease-out;
            transform: perspective(200px) translateZ(0px);
        }

        &--1 {
            background-image: url(../../../../images/section3BG1_0.jpg);
        }

        &--2 {
            background-image: url(../../../../images/section3BG1_2.jpg);
        }

        &--3 {
            background-image: url(../../../../images/section3BG1_3.jpg);
        }
    }

    &__BG2 {
        position: absolute;
        width: 100%;
        height: 100%;

        top: 0;
        left: 0;

        background: url(../../../../images/section3BG1_1.svg) center no-repeat;
        background-size: cover;
    }

    &__content {
        position: absolute;
        width: 930px;

        top: 215px;
        left: 390px;
    }

    &__buttonwrapper {
        display: flex;
        justify-content: flex-start;

        border-bottom: 1px solid rgba(160, 168, 178, 0.4);

        padding-bottom: 20px;

        scrollbar-width: none;

        position: relative;
    }

    &__buttonwrapper_2{
        position: relative;
    }

    &__button {
        cursor: pointer;
        height: 52px;
        min-width: 170px;
        background: none;

        display: flex;
        align-items: center;
        margin-right: 20px;

        &_active {
            span {
                color: #F5F5F5;
            }
        }
        &_passive {
            span {
                color: #A0A8B3;
            }
        }

        &:nth-child(1) {
            // background-color: aqua;
            position: relative;
            left: -7px;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            span {
                color: #F5F5F5;
                transition: all 0.3s linear 0s;
            }
        }
    }

    &__buttontext_big {
        padding-right: 12px;
        position: relative;
        display: flex;
        align-items: center;
        transition: all 0.3s linear 0s;
        font-family: 'OpiumNewC';
        font-style: normal;
        font-weight: 400;
        font-size: 52px;
        line-height: 130%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #A0A8B3;
    }

    &__buttontext {
        text-align: left;
        position: relative;
        transition: all 0.3s linear 0s;
        font-family: 'OpiumNewC', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 130%;
        letter-spacing: 0.03em;
        color: #A0A8B3;
    }

    &__textwrapper {
        margin-top: 40px;

        display: flex;
        //justify-content: flex-start;
    }

    &__ul {
        min-width: 230px;
        list-style: none;

        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;

        scrollbar-width: none;

        li {
            padding-bottom: 15px;
            cursor: pointer;
            max-width: 180px;
            &:hover {
                transition: color 0.3s linear 0s;
                color: #F5F5F5;
            }
        }

        &_active {
            color: #F5F5F5;
        }

        &_passive {
            color: #A0A8B3;
            transition: color 0.3s linear 0s;
        }
    }

    &__quote {
        margin-left: 10px;

        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
        letter-spacing: 0.01em;
        color: #F5F5F5;
    }

    &__quotetext {
        scrollbar-width: none;
    }

    &__quotelogo {
        // width: 174px;
        // height: 34px;
        width: 190px;
        height: 60px;

        margin-top: 45px;

        img {
            height: inherit;
            //width: 100%;
            width: auto;
        }
    }
}

@media (max-width: 1500px) {
    .section3 {
        &__content {
            // position: absolute;
            width: 650px;

            top: 155px;
            left: 350px;
        }
    }
}

//1024 * 1919
// @media (max-width: 1919px) {
@media (max-width: 1199px) {
    .section3 {
        //min-height: 653px;

        // background: url(../../../../images/section3BG2.png) center no-repeat;
        // background-size: cover;

        &__BG1 {
            // position: absolute;
            // width: 100vw;
            // height: 100vh;

            // background: url(../../../../images/section3BG2.png) top no-repeat;
            // background-size: cover;
        }

        &__content {
            width: 620px;

            top: 138px;
            left: 190px;
        }

        &__button {
            height: 34px;
            min-width: 136px;
            margin-right: 50px;
        }

        &__buttontext_big {
            padding-right: 12px;

            font-size: 38px;
        }

        &__buttontext {
            font-size: 13px;
        }

        &__textwrapper {
            margin-top: 30px;
        }

        &__ul {
            //прикрутить прозрачный текст
            min-width: 180px;
            height: 360px;
            margin-right: 0px;
            overflow: hidden;
            overflow-y: scroll;

            font-size: 14px;

            mask-image: linear-gradient(to bottom, white 85%, transparent);

            li {
                padding-right: 20px;
            }
        }

        &__quote {
            font-size: 14px;
        }

        &__quotelogo {
            width: 153px;
            height: 55px;

            margin-top: 25px;
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .section3 {
        // min-height: 876px;

        display: flex;
        justify-content: center;

        &__content {
            //background-color: aqua;
            position: relative;

            top: 138px;
            left: 0;

            width: 435px;
        }

        &__buttonwrapper {
            justify-content: space-between;
        }

        &__button {
            width: 136px;
            margin: 0 25px 0 0;

            &:nth-child(1) {
                // background-color: aqua;
                position: relative;
                left: 0;
            }
        }

        &__textwrapper {
            flex-direction: column;
        }

        &__ul {
            // max-height: 144px;
            // max-height: auto;
            height: auto;

            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            width: 100%;
            mask-image: none;

            li {
                padding: 0;
                padding-right: 22px;

                max-width: 220px;

                height: 40px;
            }
        }

        &__quote {
            margin-top: 20px;
            margin-left: 0px;
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .section3 {
        &__BG2 {
            // position: absolute;
            // width: 100%;
            // height: 100%;

            // top: 0;
            // left: 0;

            background: none;
            // background-size: cover;
            background-color: rgba(0, 0, 0, 0.7);
        }

        &__content {
            top: 165px;
            width: 256px;
            width: 100%;
            padding-left: 32px;
            padding-right: 32px;
        }

        &__buttonwrapper {
            overflow-x: scroll;

            padding-bottom: 16px;

            mask-image: linear-gradient(to right, white 85%, transparent);
        }

        &__button {
            margin: 0;
            padding: 0;
            padding-right: 20px;
            min-width: auto;

            white-space: pre;

            span {
                height: 100%;
            }
        }

        &__buttontext_big {
            display: none;
        }

        &__textwrapper {
            margin-top: 0px;
        }

        &__ul {
            height: 60px;

            flex-wrap: nowrap;
            overflow-x: scroll;
            overflow-y: hidden;

            width: 100%;

            mask-image: linear-gradient(to right, white 85%, transparent);

            padding-bottom: 0;

            li {
                display: flex;
                align-items: center;
                height: 56px;
                padding-right: 16px;

                white-space: pre;
            }
        }

        &__quote {
            margin-top: 0;
            margin-left: 0;
        }

        &__quotetext {
            height: 272px;

            overflow: hidden;
            overflow-y: scroll;

            mask-image: linear-gradient(to bottom, white 85%, transparent);

            p{
                padding-bottom: 70px;
            }
        }

        &__quotelogo {
            // width: 153px;
            height: 45px;

            // margin-top: 25px;
        }
    }
}

.section3__quotetext--certificates {
    display: flex;
    flex-direction: column;
    gap: 25px;
    ul {
        margin-left: 20px;
    }
}

.section3__quotetext--menu-item {
    margin-top: 1rem;
}
