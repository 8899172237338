.uparrow {
    z-index: 209;
    cursor: pointer;
    position: fixed;
    right: 230px;
    bottom: 66px;
    width: 70px;
    display: flex;
    align-items: center;

    &__angle {
        transform: rotate(0deg);
        position: relative;
        height: 14px;
        width: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform .5s;

        &--up {
            transform: rotate(180deg);
        }

        &::before, &::after {
            content: '';
            position: absolute;
            height: 2px;
            width: 10px;
            background-color: #636363;
            transform: rotate(45deg);
            left: -3px;
            border-radius: 1px 0 0 1px;
        }

        &::after {
            transform: rotate(-45deg);
            left: auto;
            right: -3px;
            border-radius: 0 1px 1px 0;
        }
    }

    &__text {
        padding-left: 10px;

        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        letter-spacing: 0.07em;
        text-transform: uppercase;

        color: #A0A8B3;

        transition: color 0.3s linear;

        &:hover {
            color: #d3d3d3;
        }
    }
}

//1024 * 1919
@media (max-width: 1919px) {
    .uparrow {
        right: 200px;
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .uparrow {
        right: 62px;
        bottom: 55px;

        width: auto;

        &__text {
            display: none;
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .uparrow {
        right: 32px;
        bottom: 43px;
    }
}
