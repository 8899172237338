// 
.notfount {
    background-color: white;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__page {
        display: flex;
        justify-content: center;

        h1{
            padding-top: 100px;
        }
    }
}