//
.projects3 {
    height: 456px;

    background-color: #F8F8F8;

    display: flex;
    justify-content: center;
    align-items: center;

    &__page {
        max-width: 1200px;
        flex: 1 0 100%;

        display: flex;
    }

    &__head {
        position: relative;
        top: -10px;


        flex: 1 0 50%;
        height: 170px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h3 {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 44px;
            line-height: 135%;
            letter-spacing: 0.02em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            color: #0F2538;
        }
    }

    &__link {
        align-self: flex-end;

        padding-top: 120px;
    }

    &__text {
        flex: 1 0 50%;

        p {
            width: 513px;

            border-bottom: solid 1px black;
            border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 1;
            padding-bottom: 40px;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 180%;
            letter-spacing: 0.01em;
            color: #38414D;
        }
    }
}

//1024 * 1919
@media (max-width: 1199px) {
    .projects3 {
        &__page {
            max-width: 924px;
            // flex: 1 0 100%;

            // display: flex;
        }

        &__head {
            // position: relative;
            top: -6px;

            // flex: 1 0 50%;
            height: 190px;

            // display: flex;
            // flex-direction: column;
            // justify-content: space-between;

            h3 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 29px;
                // line-height: 135%;
                // letter-spacing: 0.02em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__link {
            // align-self: flex-end;
    
            padding-top: 215px;
        }

        &__text {
            // flex: 1 0 50%;

            p {
                width: 400px;

                // border-bottom: solid 1px black;
                // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 1;
                // padding-bottom: 40px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 16px;
                // line-height: 180%;
                // letter-spacing: 0.01em;
                // color: #38414D;
            }
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .projects3 {
        height: 580px;

        &__page {
            max-width: 668px;
            // flex: 1 0 100%;

            // display: flex;
            flex-direction: column-reverse;
        }

        &__head {
            flex: 1 0 100%;
            // height: 190px;

            // display: flex;
            // flex-direction: column;
            // justify-content: space-between;

            h3 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 25px;
                // line-height: 135%;
                // letter-spacing: 0.02em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__heading {
            width: 319px;
        }

        &__text {
            flex: 1 0 100%;

            padding-top: 58px;

            p {
                width: 435px;

                // border-bottom: solid 1px black;
                // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 1;
                // padding-bottom: 40px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 15px;
                line-height: 160%;
                // letter-spacing: 0.01em;
                // color: #38414D;
            }
        }

        &__link {
            align-self: center;

            padding-top: 34px;
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .projects3 {
        height: 700px;

        &__page {
            // max-width: 256px;
            width: 100%;
            padding-left: 32px;
            padding-right: 32px;
            // flex: 1 0 100%;

            // display: flex;
            // flex-direction: column-reverse;
        }

        &__head {
            flex: 1 0 100%;
            // height: 190px;

            // display: flex;
            // flex-direction: column;
            // justify-content: space-between;

            h3 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 22px;
                // line-height: 135%;
                // letter-spacing: 0.02em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__heading {
            width: 100%;
        }

        &__link {
            align-self: flex-end;

            // padding-top: 34px;
        }

        &__text {
            // flex: 1 0 100%;

            // padding-top: 58px;

            p {
                width: 100%;

                // border-bottom: solid 1px black;
                // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 1;
                padding-bottom: 32px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 14px;
                line-height: 160%;
                // letter-spacing: 0.01em;
                // color: #38414D;
            }
        }
    }
}