.slidermobile3 {
    width: 100%;

    &__photo {
        width: 100%;
        height: 400px;

        position: relative;

        overflow: hidden;
    }

    &__photoimg {
        width: 100%;
        height: 400px;

        overflow: hidden;

        display: flex;
        justify-content: center;

        img {
            display: block;
            height: 100%;
            width: auto;
        }
    }

    &__buttons {
        height: 68px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        &_left {
            transform: rotate(180deg);
        }

        &_right,
        &_left {
            cursor: pointer;
        }
    }
}