// 
.partners8 {
    height: 556px;
    background-color: #F8F8F8;

    display: flex;
    justify-content: center;
    align-items: center;

    &__page {
        max-width: 1200px;
        overflow: hidden;
    }

    &__text {
        font-family: 'OpiumNewC';
        font-style: normal;
        font-weight: 400;
        font-size: 44px;
        line-height: 135%;
        text-align: center;
        letter-spacing: 0.02em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
        color: #0F2538;
    }
}

//1024 * 1919
@media (max-width: 1199px) {
    .partners8 {
        height: 476px;

        &__page {
            max-width: 925px;
        }

        &__text {
            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 29px;
            // line-height: 135%;
            // text-align: center;
            // letter-spacing: 0.02em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #0F2538;
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .partners8 {
        height: 416px;

        &__page {
            max-width: 667px;
        }

        &__text {
            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 25px;
            // line-height: 135%;
            // text-align: center;
            // letter-spacing: 0.02em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #0F2538;
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .partners8 {
        height: 530px;

        &__page {
            // max-width: 256px;

            max-width: 100%;
            width: 100%;
            padding-left: 32px;
            padding-right: 32px;
        }

        &__text {
            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 22px;
            // line-height: 135%;
            // text-align: center;
            // letter-spacing: 0.02em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #0F2538;
        }
    }
}