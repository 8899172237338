// 
.vacancy6 {
    display: flex;
    justify-content: center;

    background: #F8F8F8;

    &__page {
        max-width: 1200px;
        flex: 1 0 100%;

        padding: 140px 0;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__text {

        p {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 44px;
            line-height: 135%;
            text-align: center;
            letter-spacing: 0.02em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            color: #0F2538;
        }
    }
}

//1024 * 1919
@media (max-width: 1199px) {
    .vacancy6 {
        &__page {
            max-width: 924px;
            // flex: 1 0 100%;

            // padding: 140px 0;
        }

        &__text {
            width: 700px;

            p {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 29px;
                // line-height: 135%;
                // text-align: center;
                // letter-spacing: 0.02em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .vacancy6 {
        &__page {
            max-width: 668px;
            // flex: 1 0 100%;

            padding: 120px 0;
        }

        &__text {
            width: 100%;

            p {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 25px;
                // line-height: 135%;
                // text-align: center;
                // letter-spacing: 0.02em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .vacancy6 {

        &__page {
            // max-width: 256px;
            // flex: 1 0 100%;

            padding: 80px 0;

            max-width: 100%;
            padding-left: 32px;
            padding-right: 32px;
        }

        &__text {
            width: 700px;

            p {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 22px;
                // line-height: 135%;
                // text-align: center;
                // letter-spacing: 0.02em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }
    }
}