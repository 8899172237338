body {
    background: linear-gradient(to bottom right, #0a172d, #121416);
    overscroll-behavior-y: contain;
}

.page {
    overflow: hidden;
    height: var(--100vh, 100vh);
    width: 100vw;

    &__container {
        will-change: transform;
        transition: transform 1s cubic-bezier(.16, 1, .3, 1); /* https://easings.net/#easeOutExpo */
    }
}

.section {
    overflow: hidden;
    height: var(--100vh, 100vh);
    width: 100vw;
    position: relative;
}
