
.headerpage {
    //background-color: aquamarine;
    z-index: 210;
    width: 100%;

    position: relative;
    top: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__logo {
        z-index: 1000;
        position: relative;
        left: 150px;
        height: 56px;
        width: 180px;

        display: flex;
        align-items: center;
        justify-content: left;

        &_white {
            position: absolute;
            height: 100%;
            width: auto;
        }

        &_blue {
            position: absolute;
            height: 100%;
            width: auto;
        }
    }

    &__links {
        display: flex;
        flex-wrap: wrap;

        a {
            padding: 10px 20px;
            text-decoration: none;
            // color: #A0A8B3;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.07em;
            text-transform: uppercase;

            transition: color 0.3s linear;

            &:hover {
                color: white;
            }
        }

        &_active{
            color: white;
        }
        &_passive{
            color: #A0A8B3;
        }
    }

    &__burger {
        opacity: 0;

        height: 40px;
        width: 40px;
    }
}

//1024 * 1919
@media (max-width: 1919px) {
    .headerpage {
        //background-color: rgb(255, 196, 124);
        top: 46px;

        &__logo {
            left: 50px;
            height: 39px;
            width: 120px;
        }

        &__links {
            a {
                padding: 0 15px;
            }
        }

        &__openlinks {
            background:
                linear-gradient(238.17deg,
                    rgba(12, 18, 26, 0.6) 37.87%,
                    rgba(7, 21, 43, 0.7) 59.92%,
                    rgba(6, 23, 51, 0.9) 80.92%,
                    #021533 91.44%);
        }

        &__linkswrapper {
            top: 127px;
            left: 245px;

            a {
                font-size: 38px;
            }
        }

        &__burger {
            opacity: 1;

            &_false{
                opacity: 1;
            }
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .headerpage {
        //background-color: rgb(93, 255, 53);

        &__links {
            display: none;
        }

        &__linkswrapper {
            top: 256px;
            left: 166px;

            a {
                font-size: 33px;
            }
        }

        &__burger {
            right: 50px;
        }

        &__burger_blue {
            span {
                background-color: #002157;
            }
        }

    }
}

//320 * 767
@media (max-width: 767px) {
    .headerpage {
        //background-color: rgb(252, 139, 1);
        top: 36px;

        &__logo {
            left: 32px;
        }

        &__linkswrapper {
            top: 162px;
            left: 32px;
        }

        &__burger {
            right: 31px;
        }
    }
}
