.partners3 {
    height: 696px;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    &__bg1 {
        position: absolute;
        top: 0;
        left: 0;
    }

    &__page {
        max-width: 1200px;
        flex: 1 0 100%;
    }

    &__numbers {
        display: flex;
        justify-content: space-between;
    }

    &__number1 {
        z-index: 1;
        padding-left: 140px;

        display: flex;

        span {
            display: block;
        }

        &_big {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 200px;
            line-height: 200px;
            display: flex;
            align-items: center;
            color: #002157;
        }

        &_middle {
            padding-top: 60px;
            margin-left: 32px;

            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 80%;
            letter-spacing: 0.03em;
            color: #002157;
        }

        &_little {
            width: 170px;
            padding-top: 20px;
            margin-left: 32px;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 130%;
            color: #0F2538;
        }
    }

    &__number23 {
        padding-right: 50px;
    }

    &__number2 {
        span {
            display: block;
        }

        &_big {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 84px;
            line-height: 84px;
            color: #002157;
        }

        &_pair {
            display: flex;
        }

        &_middle {
            padding-top: 10px;

            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 80%;
            letter-spacing: 0.03em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #002157;
        }

        &_little {
            width: 50px;
            margin-left: 8px;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 130%;
            color: #0F2538;
        }
    }

    &__number2:nth-child(1) {
        margin-bottom: 128px;
    }
}


//1024 * 1919
// @media (max-width: 1919px) {
@media (max-width: 1199px) {
    .partners3 {

        &__bg1 {
            // position: absolute;
            // top: 0;
            // left: 0;
            height: 100%;

            img {
                height: 100%;
            }
        }

        &__page {
            max-width: 925px;
            flex: 1 0 100%;
        }

        &__number1 {
            // z-index: 1;
            padding-left: 120px;

            // display: flex;

            span {
                // display: block;
            }

            &_big {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 151px;
                line-height: 152px;
                // display: flex;
                // align-items: center;
                // color: #002157;
            }

            &_middle {
                padding-top: 50px;
                margin-left: 24px;

                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 22px;
                // line-height: 80%;
                // letter-spacing: 0.03em;
                // color: #002157;
            }

            &_little {
                // width: 170px;
                padding-top: 15px;
                margin-left: 24px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 16px;
                // line-height: 130%;
                // color: #0F2538;
            }
        }

        &__number2 {
            span {
                // display: block;
            }

            &_big {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 71px;
                line-height: 71px;
                // color: #002157;
            }

            &_pair {
                // display: flex;
            }

            &_middle {
                // padding-top: 10px;

                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 700;
                font-size: 25px;
                // line-height: 80%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;
            }

            &_little {
                // width: 50px;
                // margin-left: 15px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 16px;
                // line-height: 130%;
                // color: #0F2538;
            }
        }

        &__number2:nth-child(1) {
            margin-bottom: 120px;
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .partners3 {
        height: 630px;

        &__page {
            max-width: 668px;
            // flex: 1 0 100%;
        }

        &__number1 {
            // z-index: 1;
            padding-left: 80px;

            // display: flex;

            span {
                // display: block;
            }

            &_big {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 121px;
                line-height: 150px;
                // display: flex;
                // align-items: center;
                // color: #002157;
            }

            &_middle {
                padding-top: 20px;
                margin-left: 12px;

                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 19px;
                // line-height: 80%;
                // letter-spacing: 0.03em;
                // color: #002157;
            }

            &_little {
                // width: 170px;
                padding-top: 30px;
                margin-left: 12px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 15px;
                // line-height: 130%;
                // color: #0F2538;
            }
        }

        &__number23 {
            // padding-right: 50px;
            padding-top: 20px;
        }

        &__number2 {
            span {
                // display: block;
            }

            &_big {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 71px;
                line-height: 71px;
                // color: #002157;
            }

            &_pair {
                // display: flex;
            }

            &_middle {
                // padding-top: 10px;

                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 700;
                font-size: 25px;
                // line-height: 80%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;
            }

            &_little {
                // width: 50px;
                // margin-left: 15px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 15px;
                // line-height: 130%;
                // color: #0F2538;
            }
        }

        &__number2:nth-child(1) {
            margin-bottom: 90px;
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .partners3 {

        &__bg1 {
            // position: absolute;
            // top: 0;
            // left: 0;
            height: 60%;

            img {
                height: 100%;
            }
        }

        &__page {
            max-width: 256px;
            // flex: 1 0 100%;
        }

        &__numbers {
            // display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }

        &__number1 {
            // z-index: 1;
            padding-left: 0px;

            // display: flex;

            span {
                // display: block;
            }

            &_big {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 88px;
                line-height: 109px;
                // display: flex;
                // align-items: center;
                // color: #002157;
            }

            &_middle {
                padding-top: 14px;
                margin-left: 6px;

                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 17px;
                // line-height: 80%;
                // letter-spacing: 0.03em;
                // color: #002157;
            }

            &_little {
                width: 80px;
                padding-top: 18px;
                margin-left: 6px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 14px;
                // line-height: 130%;
                // color: #0F2538;
            }
        }

        &__number23 {
            padding-right: 0px;
            padding-left: 30px;
            padding-top: 80px;
        }

        &__number2 {
            span {
                // display: block;
            }

            &_big {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 50px;
                line-height: 62px;
                // color: #002157;

                &_1{
                    position: relative;
                    left: -10px;
                }
            }

            &_pair {
                // display: flex;
            }

            &_middle {
                padding-top: 12px;
                padding-left: 6px;

                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 700;
                font-size: 18px;
                // line-height: 80%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;

                &_1{
                    padding-left: 0px;
                    position: relative;
                    left: -5px;
                }
            }

            &_little {
                width: 120px;
                margin-left: 0px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 14px;
                // line-height: 130%;
                // color: #0F2538;
            }
        }

        &__number2:nth-child(1) {
            margin-bottom: 30px;
        }

    }
}