.control {
    position: fixed;
    right: 125px;
    width: 10px;
    height: 100%;
    z-index: 200;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &__pointwrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    &__point {
        cursor: pointer;
        background: url(../../images/control1.svg) center no-repeat;
        padding: 10px;
        margin: 15px -10px;

        &::before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            background: url(../../images/control2.svg) center no-repeat;
            opacity: 0;
            transition: opacity .3s;
        }

        &.active::before, &:hover::before {
            opacity: 1;
        }
    }

    //отбивка верхняя
    &__line1 {
        width: 1px;
        height: 177px;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.02));
    }

    //отбивка нижняя
    &__line2 {
        width: 1px;
        height: 177px;
        background: linear-gradient(to top, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.02));
    }
}

//1024 * 1919
@media (max-width: 1919px) {
    .control {
        &__point {
            margin: 6px -10px;
        }

        &__line1 {
            height: 124px;
        }

        &__line2 {
            height: 124px;
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .control {
        right: 64px;

        &__pointwrapper {
            height: 100%;
            justify-content: center;
        }

        &__line1 {
            display: none;
        }

        &__line2 {
            display: none;
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .control {
        display: none;
    }
}
