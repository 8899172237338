.contacts3 {
    //height: 3000px;
    background-color: white;

    h2 {
        min-width: 410px;
        padding-top: 140px;

        font-family: 'OpiumNewC';
        font-style: normal;
        font-weight: 400;
        font-size: 44px;
        line-height: 135%;
        letter-spacing: 0.02em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
        color: #0F2538;
    }

    h3 {
        padding-bottom: 25px;

        font-family: 'OpiumNewC';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 160%;
        letter-spacing: 0.03em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
        color: #0F2538;
    }

    &__page {
        width: 1200px;

        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }

    &__office {
        display: flex;
        //justify-content: space-between;

        //padding-top: 140px;
    }

    &__addressarr {
        padding-top: 140px;
    }

    &__address {
        padding: 10px 0 50px;
    }

    &__address2 {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #38414D;
    }

    &__street {
        display: block;
        width: 430px;

        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #38414D;
    }

    &__phone {
        display: block;
        width: 180px;

        a {
            text-decoration: none;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #38414D;

            &:hover{
                color: #293896;
                // color: #A5ADB8;
            }
        }
    }

    &__mail {
        display: block;
        width: 180px;

        a {
            text-decoration: none;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #38414D;

            &:hover{
                color: #293896;
                // color: #A5ADB8;
            }
        }
    }

    &__map {
        margin-top: 20px;

        max-width: 790px;
        height: 520px;
        background: #e4e4e4;

        display: flex;
        justify-content: center;
        align-items: center;

        overflow: hidden;
    }
}

//1024 * 1919
@media (max-width: 1199px) {
    .contacts3 {
        &__page {
            max-width: 915px;
        }

        h2 {
            min-width: 320px;
            font-size: 29px;
        }

        h3 {
            font-size: 18px;
        }

        &__map {
            width: 606px;
        }

        &__address2 {
            //width: 100%;
        }

        &__street {
            width: 200px;

        }

        &__phone {
            width: 150px;
            margin-left: 40px;
        }

        &__mail {
            width: 120px;
            margin-left: 40px;
        }

    }
}

//768 * 1023
@media(max-width: 1023px) {
    .contacts3 {
        &__page {
            padding: 0 50px;
            max-width: 768px;
        }

        h2 {
            font-size: 25px;
        }

        h3 {
            font-size: 17px;
        }

        &__addressarr {
            padding-top: 60px;
        }

        &__office {
            display: flex;
            flex-direction: column;
        }

        &__street {
            width: 320px;
            font-size: 15px;
        }

        &__phone {
            font-size: 15px;

            padding: 0;
            margin: 0;

            a {
                color: #403EC0;
            }

        }


        &__mail {
            font-size: 15px;

            a {
                color: #403EC0;
            }
        }

        &__map {
            margin-top: 20px;

            min-width: 667px;
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .contacts3 {
        &__page {
            padding: 0 0px;
            // max-width: 320px;
            max-width: 100%;

            overflow: hidden;
        }

        h2 {
            padding: 0 32px;
            padding-top: 80px;
            font-size: 25px;
        }

        h3 {
            font-size: 16px;
            padding: 0 32px;
        }

        &__addressarr {
            padding-top: 32px;
            //padding: 0 32px;
        }

        &__address {
            padding-top: 0px;
        }

        &__office {
            display: flex;
            flex-direction: column;
        }

        &__address2 {
            padding-top: 10px;
            flex-direction: column;
        }

        &__street {
            // width: 320px;
            width: 100%;
            font-size: 14px;
            margin-bottom: 20px;
            padding: 0 32px;
        }

        &__phone {
            width: 100%;
            font-size: 14px;
            margin-left: 0;
            margin-bottom: 20px;
            padding: 0 32px;
        }

        &__mail {
            font-size: 14px;
            margin-left: 0;
            padding: 0 32px;
        }

        &__map {
            min-width: 100%;
            max-width: 100%;
            //min-width: 320px;
            //width: 320px;
            height: 400px;
            margin: 0;
            padding: 0;
            overflow: hidden;
            margin-top: 20px;

            //position: relative;
            //left: -32px;

            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}