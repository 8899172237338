// 
.company3 {
    height: 465px;
    display: flex;
    justify-content: center;

    background-color: #F8F8F8;

    &__page {
        max-width: 1070px;
        flex: 1 0 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__number1,
    &__number2 {
        display: flex;
        align-items: center;
    }

    &__text {
        &_right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            height: 150px;
        }

        &_big {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 200px;
            line-height: 200px;
            color: #002157;
        }

        &_middle {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 700;
            font-size: 75px;
            line-height: 50%;
            letter-spacing: 0.03em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #002157;
        }

        &_little {
            width: 150px;
            padding-left: 15px;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 130%;
            color: #0F2538;
        }
    }

    &__and {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span {
            display: block;
        }

        &_top {
            height: 100px;
            width: 1px;
            background-image: linear-gradient(to top, rgba(140, 154, 176, 1), rgba(140, 154, 176, 0));
        }

        &_central {
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 180%;
            letter-spacing: 0.01em;
            color: #38414D;
        }

        &_bottom {
            height: 100px;
            width: 1px;
            background-image: linear-gradient(to bottom, rgba(140, 154, 176, 1), rgba(140, 154, 176, 0));
        }
    }
}

//1700 * 1919
@media (max-width: 1919px) {
    .company3 {

        &__text {
            &_right {
                // display: flex;
                // flex-direction: column;
                // justify-content: space-between;

                // height: 150px;
            }

            &_big {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 183px;
                line-height: 184px;
                // color: #002157;
            }

            &_middle {
                padding-top: 10px;

                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 700;
                font-size: 68px;
                // line-height: 50%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;
            }

            &_little {
                // width: 150px;
                // padding-left: 15px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 20px;
                // line-height: 130%;
                // color: #0F2538;

                &1 {
                    padding-bottom: 5px;
                }

                &2 {
                    padding-top: 5px;
                }
            }
        }
    }
}

//1280 * 1699
@media (max-width: 1699px) {
    .company3 {
        &__page {
            // background-color: aquamarine;
            max-width: 940px;
            // flex: 1 0 100%;

            // display: flex;
            // justify-content: space-between;
            // align-items: center;
        }

        &__text {
            &_right {
                // display: flex;
                // flex-direction: column;
                // justify-content: space-between;

                // height: 150px;
            }

            &_big {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 152px;
                line-height: 152px;
                // color: #002157;
            }

            &_middle {
                padding-top: 20px;
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 700;
                font-size: 57px;
                // line-height: 50%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;
            }

            &_little {
                // width: 150px;
                // padding-left: 15px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 20px;
                // line-height: 130%;
                // color: #0F2538;

                &1 {
                    padding-bottom: 15px;
                }

                &2 {
                    padding-top: 15px;
                }
            }
        }
    }
}

//1024 * 1279
@media (max-width: 1279px) {
    .company3 {
        &__page {
            // background-color: aquamarine;
            max-width: 924px;
            // flex: 1 0 100%;

            // display: flex;
            // justify-content: space-between;
            // align-items: center;
        }

        &__text {
            &_right {
                // display: flex;
                // flex-direction: column;
                // justify-content: space-between;

                // height: 150px;
            }

            &_big {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 152px;
                line-height: 152px;
                // color: #002157;
            }

            &_middle {
                padding-top: 0px;
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 700;
                font-size: 57px;
                // line-height: 50%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;
            }

            &_little {
                // width: 150px;
                // padding-left: 15px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 20px;
                // line-height: 130%;
                // color: #0F2538;

                &1 {
                    padding-bottom: 0px;
                }

                &2 {
                    padding-top: 0px;
                }
            }
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .company3 {
        height: 628px;

        &__page {
            // background-color: aquamarine;
            max-width: 443px;
            // flex: 1 0 100%;

            // display: flex;
            justify-content: center;
            align-items: flex-start;

            flex-direction: column;
        }

        &__number1 {
            align-self: flex-end;

            position: relative;
            right: -100px;
        }

        &__text {
            &_right {
                // display: flex;
                // flex-direction: column;
                // justify-content: space-between;

                height: 90px;
            }

            &_big {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 119px;
                line-height: 119px;
                // color: #002157;
            }

            &_middle {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 700;
                font-size: 44px;
                // line-height: 50%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;
            }

            &_little {
                width: 110px;
                // padding-left: 15px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 17px;
                // line-height: 130%;
                // color: #0F2538;
            }
        }

        &__and {
            align-self: center;
            // display: flex;
            flex-direction: row;
            // justify-content: center;
            // align-items: center;

            padding: 80px 0 80px;

            span {
                // display: block;
            }

            &_top {
                height: 1px;
                width: 100px;
                background-image: linear-gradient(to left, rgba(140, 154, 176, 1), rgba(140, 154, 176, 0));
            }

            &_central {
                padding: 0 30px 0;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 16px;
                // line-height: 180%;
                // letter-spacing: 0.01em;
                // color: #38414D;
            }

            &_bottom {
                height: 1px;
                width: 100px;
                background-image: linear-gradient(to right, rgba(140, 154, 176, 1), rgba(140, 154, 176, 0));
            }
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .company3 {
        height: 480px;

        &__page {
            // background-color: aquamarine;
            max-width: 256px;
            // flex: 1 0 100%;

            // display: flex;
            // justify-content: center;
            // align-items: flex-start;

            // flex-direction: column;

            padding-bottom: 40px;
        }

        &__number1 {
            align-self: auto;

            position: static;
            // right: -100px;
        }

        &__text {
            &_right {
                // display: flex;
                // flex-direction: column;
                // justify-content: space-between;

                height: 70px;
            }

            &_big {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 88px;
                line-height: 89px;
                // color: #002157;
            }

            &_middle {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 700;
                font-size: 33px;
                // line-height: 50%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;
            }

            &_little {
                // width: 150px;
                padding-left: 7px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 14px;
                // line-height: 130%;
                // color: #0F2538;
            }
        }

        &__button {
            position: relative;
            bottom: -40px;
        }

        &__and {
            // align-self: center;
            // display: flex;
            // flex-direction: row;
            // justify-content: center;
            // align-items: center;

            padding: 60px 0 60px;

            span {
                // display: block;
            }

            &_top {
                // height: 1px;
                // width: 100px;
                // background-image: linear-gradient(to left, rgba(140, 154, 176, 1), rgba(140, 154, 176, 0));
            }

            &_central {
                // padding: 0 30px 0;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 16px;
                // line-height: 180%;
                // letter-spacing: 0.01em;
                // color: #38414D;
            }

            &_bottom {
                // height: 1px;
                // width: 100px;
                // background-image: linear-gradient(to right, rgba(140, 154, 176, 1), rgba(140, 154, 176, 0));
            }
        }
    }
}