.section6 {
    display: flex;
    justify-content: center;
    align-items: center;

    &__BG1 {
        position: absolute;
        height: var(--100vh, 100vh);
        width: 100vw;
        background: url(../../../../images/section6BG1_1.jpg) center no-repeat;
        background-size: cover;
        transition: none;
        transform: perspective(200px) translateZ(20px);
        opacity: 0;

        &--enter {
            transition: transform 3s ease-out, opacity 3s ease-out;
            transform: perspective(200px) translateZ(0px);
            opacity: 1;
        }
    }

    &__BG2 {
        position: absolute;
        height: var(--100vh, 100vh);
        width: 100vw;

        background: url(../../../../images/section6BG1_0.svg) center no-repeat;
        background-size: cover;
    }

    &__content {
        position: relative;
        width: 690px;
    }

    &__text {
        p {
            padding-bottom: 100px;

            display: block;
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 44px;
            line-height: 135%;
            letter-spacing: 0.02em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            color: #F5F5F5;
        }
    }

    &__links {
        display: flex;
        justify-content: space-between;
    }

    &__link {
        display: flex;
        align-items: center;

        a {
            text-decoration: none;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 130%;
            color: #A0A8B3;

            &:hover {
                color: #F5F5F5
            }
        }
    }

    &__arrow {
        width: 110px;
        margin-right: 20px;

        img {
            width: 100%;
        }
    }

    &__footer {
        position: absolute;
        bottom: 0;
        z-index: 1;
        height: 73px;
        width: 1170px;
    }

    &__line {
        height: 1px;
        width: 1170px;
        background: linear-gradient(to right, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.02));
    }

    &__footercontent {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 95%;
        color: #A0A8B3;
    }

    &__copyright {}

    &__agreement {
        a {
            text-decoration: none;
            color: #A0A8B3;

            transition: color 0.3s linear;

            &:hover {
                color: #F5F5F5
            }
        }
    }

}

//1024 * 1919
// @media (max-width: 1919px) {
@media (max-width: 1700px) {

    // @media (max-width: 1200px) {
    .section6 {
        //min-height: 684px;

        &__line {
            // height: 1px;
            width: 900px;
            // background: linear-gradient(to right, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.02));
        }

        &__footer {
            // position: absolute;
            // bottom: 0;
            // z-index: 1;
            // height: 73px;
            width: 900px;
        }
    }
}

//1024 * 1919
// @media (max-width: 1919px) {
@media (max-width: 1500px) {

    // @media (max-width: 1200px) {
    .section6 {
        //min-height: 684px;

        &__line {
            // height: 1px;
            width: 680px;
            // background: linear-gradient(to right, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.02));
        }

        &__footer {
            // position: absolute;
            // bottom: 0;
            // z-index: 1;
            // height: 73px;
            width: 680px;
        }
    }
}

//1024 * 1919
// @media (max-width: 1919px) {
@media (max-width: 1200px) {

    // @media (max-width: 1200px) {
    .section6 {
        //min-height: 684px;

        &__content {
            width: 471px;

            // top: 203px;
        }

        &__text {
            p {
                padding-bottom: 60px;
                font-size: 29px;
            }
        }

        &__arrow {
            width: 100px;
        }

        &__footer {
            z-index: 1;
            height: 73px;
            width: 630px;
        }

        &__line {
            width: 531px;
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .section6 {
        //min-height: 900px;

        // background: url(../../../../images/section6BG2.png) center no-repeat;
        // background-size: cover;

        &__BG1 {
            position: absolute;
            height: var(--100vh, 100vh);
            width: 100vw;

            background: url(../../../../images/section6BG2_1.jpg) center no-repeat;
            background-size: cover;
        }

        &__BG2 {
            // display: none;
            position: absolute;
            height: var(--100vh, 100vh);
            width: 100vw;

            background: url(../../../../images/section6BG2_0.svg) center no-repeat;
            background-size: cover;
        }

        &__content {
            width: 398px;
            // top: 322px;
        }

        &__text {
            p {
                padding-bottom: 57px;
                font-size: 25px;
            }
        }

        &__links {
            display: flex;
            flex-direction: column;
            justify-content: left;
        }

        &__link {
            padding-bottom: 36px;
        }

        &__footer {
            z-index: 1;
            height: 60px;
            width: 552px;

            align-self: flex-start;
            margin-left: 50px;
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .section6 {
        //min-height: 568px;

        // background: url(../../../../images/section6BG3.png) center no-repeat;
        // background-size: cover;

        &__BG1 {
            position: absolute;
            height: var(--100vh, 100vh);
            width: 100vw;

            background: url(../../../../images/section6BG3.png) center no-repeat;
            background-size: cover;
        }

        &__BG2 {
            display: none;
        }

        &__content {
            // width: 256px;
            width: auto;
            padding-left: 32px;
            padding-right: 32px;
            // top: 180px;
        }

        &__text {
            p {
                padding-bottom: 42px;

                font-family: 'OpiumNewC';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 150%;
            }
        }

        &__arrow {
            width: 60px;
        }

        &__link {
            padding-bottom: 21px;

            a {
                font-size: 18px;
            }
        }

        &__footer {
            z-index: 1;
            height: 90px;
            // width: 256px;
            width: 100%;
            padding-left: 32px;
            padding-right: 32px;

            align-self: center;
            margin-left: 0px;
        }

        &__line {
            // width: 256px;
            width: 100%;
        }

        &__footercontent {
            padding-top: 17px;

            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            font-size: 10px;
            line-height: 140%;
        }

        &__copyright {
            width: 137px;
            padding-bottom: 10px;
        }

        &__agreement {}
    }
}
