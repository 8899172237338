.description {
    position: fixed;
    z-index: 200;
    height: 12px;
    top: 220px;
    left: 0;
    display: flex;
    align-items: center;

    &__line {
        height: 1px;
        width: 120px;
        background: #A0A8B2;
        transition: opacity .25s;
        opacity: 0;

        &--active {
            opacity: .4;
        }
    }

    &__text {
        padding-left: 30px;
        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: #A0A8B3;
        opacity: 0;
        transition: opacity .25s ease-in;

        &--active {
            opacity: 1;
            transition: opacity .8s .25s ease-out;
        }
    }
}

//1024 * 1919
// @media (max-width: 1919px) {
    @media (max-width: 1199px) {
    .description {
        top: 143px;

        &__line {
            width: 25px;
        }

        &__text {
            padding-left: 25px;
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {}

//320 * 767
@media (max-width: 767px) {
    .description {
        top: 115px;
        left: 32px;

        &__line {
            display: none;
        }

        &__text {
            padding-left: 0px;
        }
    }
}
