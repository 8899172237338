.sitemap {
    background-color: white;

    min-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding-top: 85px;

    &__page {
        margin: 0 auto;
        width: 1200px;
        flex: 1 0 100%;

        h1 {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 44px;
            line-height: 135%;
            letter-spacing: 0.02em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            color: #0F2538;
        }

        a {
            text-decoration: none;
            color: #747b85;

            &:hover {
                text-decoration: underline;
            }
        }

        ul {
            padding: 50px 0 50px 20px;
        }

        li {
            // height: 30px;
            padding: 8px 0;
        }
    }

    &__footer {
        position: relative;
        bottom: 0;
    }
}

@media (max-width: 1199px) {
    .sitemap {
        &__page {
            // margin: 0 auto;
            width: 924px;
            // flex: 1 0 100%;
        }
    }
}

@media(max-width: 1023px) {
    .sitemap {
        &__page {
            // margin: 0 auto;
            width: 668px;
            // flex: 1 0 100%;
        }
    }
}

@media (max-width: 767px) {
    .sitemap {
        &__page {
            // margin: 0 auto;
            max-width: 100%;
            // flex: 1 0 100%;
            padding: 0 32px;
        }
    }
}