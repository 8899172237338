//
.projects2 {

    display: flex;
    justify-content: center;
    align-items: center;

    &__page {
        max-width: 1200px;

        padding: 160px 0;
    }

    &__heading {
        h2 {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 44px;
            line-height: 135%;
            letter-spacing: 0.02em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            color: #0F2538;
        }
    }

    &__links {
        padding-top: 50px;

        display: flex;
        flex-direction: column;
        overflow-x: scroll;

        scrollbar-width: none;

        mask-image: linear-gradient(to right, white 80%, transparent);

        span {
            padding: 10px 35px 10px 0;
            cursor: pointer;
            white-space: nowrap;
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 130%;
        }

        &_inline {
            // overflow-x: scroll;
            // scrollbar-width: none;

            height: 80px;
            width: 1300px;

            display: flex;
            align-items: center;

            position: relative;

            transition: left 0.3s ease-out;
        }

        &_active {
            color: #002157;
        }

        &_passive {
            color: #A0A8B3;
            transition: all 0.4s linear 0s;

            &:hover {
                color: #002157;
            }
        }
    }

    &__button1 {
        height: 50px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        &_right,
        &_left {
            cursor: pointer;
            width: 100px;
            height: 100%;

            display: flex;
            align-items: center;

            img {
                display: block;

                width: 100%;
                height: auto;
            }
        }

        &_left {
            transform: rotate(180deg);
        }
    }

    &__project {
        padding-top: 95px;

        display: flex;
    }

    &__icon {
        flex: 1 0 50%;

        a {
            width: 300px;
            height: 110px;

            display: flex;
            align-items: center;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &__slogan {
        flex: 1 0 50%;

        p {
            border-bottom: solid 1px black;
            border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 1;
            padding-bottom: 40px;

            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 160%;
            letter-spacing: 0.03em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            color: #38414D;
        }
    }

    &__content {
        padding-top: 80px;
        display: flex;
    }

    &__text {
        flex: 1 0 50%;

        padding-top: 100px;

        p {
            width: 483px;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 180%;
            letter-spacing: 0.01em;
            color: #38414D;
        }
    }

    &__services {
        flex: 1 0 50%;
    }

    &__servicesheading {
        h3 {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 44px;
            line-height: 135%;
            letter-spacing: 0.02em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            color: #0F2538;
        }
    }

    &__servicesul {
        padding-top: 40px;

        ul {
            padding-left: 20px;
            display: flex;
            flex-wrap: wrap;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 120%;
            color: #38414D;
        }

        li {
            width: 50%;
            padding-bottom: 12px;
            // padding-right: 25px;
        }

        li:nth-child(2n + 1) {
            margin-right: 40px;
        }

        li:nth-child(2n) {
            // margin-right: 40px;
            width: calc(50% - 40px);
        }
    }

    &__button2 {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        height: 50px;

        a {
            display: flex;
            text-decoration: none;

            span {
                display: block;

                font-family: 'Noto Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 130%;
                color: #A0A8B3;
            }

            img {
                margin-right: 20px;

                display: block;
                width: 100px;
            }

            &:hover {
                span {
                    color: #002157;
                }
            }
        }
    }
}

//1024 * 1919
@media (max-width: 1199px) {
    .projects2 {
        &__page {
            max-width: 924px;

            // padding: 160px 0;
        }

        &__heading {
            h2 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 29px;
                // line-height: 135%;
                // letter-spacing: 0.02em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__links {
            // padding-top: 50px;

            // display: flex;
            // flex-direction: column;
            // overflow-x: scroll;

            span {
                // padding-right: 35px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 20px;
                // line-height: 130%;
            }

            &_inline {
                // height: 80px;
                // width: 1240px;

                // display: flex;
                // align-items: center;
            }

            &_active {
                // color: #002157;
            }

            &_passive {
                // color: #A0A8B3;

                &:hover {
                    // cursor: pointer;
                    // color: #002157;
                }
            }
        }

        &__slogan {
            // flex: 1 0 50%;

            p {
                // border-bottom: solid 1px black;
                // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 1;
                // padding-bottom: 40px;

                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 18px;
                // line-height: 160%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #38414D;
            }
        }

        &__text {
            // flex: 1 0 50%;

            padding-top: 75px;

            p {
                width: 368px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 16px;
                // line-height: 180%;
                // letter-spacing: 0.01em;
                // color: #38414D;
            }
        }

        &__servicesheading {
            h3 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 29px;
                // line-height: 135%;
                // letter-spacing: 0.02em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .projects2 {

        &__page {
            max-width: 668px;

            padding: 140px 0;
        }

        &__heading {
            h2 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 24px;
                // line-height: 135%;
                // letter-spacing: 0.02em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__links {
            // padding-top: 50px;

            // display: flex;
            // flex-direction: column;
            // overflow-x: scroll;

            span {
                // padding-right: 35px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 18px;
                // line-height: 130%;
            }

            &_inline {
                // overflow-x: scroll;

                // height: 80px;
                width: 1200px;

                // display: flex;
                // align-items: center;
            }

            &_active {
                // color: #002157;
            }

            &_passive {
                // color: #A0A8B3;

                &:hover {
                    // cursor: pointer;
                    // color: #002157;
                }
            }
        }

        &__button1 {
            // cursor: pointer;
            height: 30px;

            // display: flex;
            // justify-content: flex-end;
            // align-items: center;
        }

        &__project {
            padding-top: 70px;

            display: flex;
            flex-direction: column;
        }

        &__slogan {
            // flex: 1 0 50%;

            padding-top: 60px;

            p {
                width: 70%;

                //     border-bottom: solid 1px black;
                //     border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 1;
                //     padding-bottom: 40px;

                //     font-family: 'OpiumNewC';
                //     font-style: normal;
                //     font-weight: 400;
                //     font-size: 22px;
                //     line-height: 160%;
                //     letter-spacing: 0.03em;
                //     font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                //     color: #38414D;
            }
        }

        &__content {
            padding-top: 48px;
            display: flex;
            flex-direction: column;
        }

        &__text {
            // flex: 1 0 50%;

            padding-top: 0px;

            p {
                width: 470px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 15px;
                line-height: 160%;
                // letter-spacing: 0.01em;
                // color: #38414D;
            }
        }

        &__servicesheading {
            padding-top: 60px;

            h3 {
                //     font-family: 'OpiumNewC';
                //     font-style: normal;
                //     font-weight: 400;
                font-size: 25px;
                //     line-height: 135%;
                //     letter-spacing: 0.02em;
                //     font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                //     color: #0F2538;
            }
        }

        &__servicesul {
            // padding-top: 40px;

            ul {
                width: 455px;
                padding-left: 20px;
                // display: flex;
                // flex-wrap: wrap;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 14px;
                // line-height: 120%;
                // color: #38414D;
            }

            li {
                width: 50%;
                // padding-bottom: 12px;
                // padding-right: 25px;
            }
        }

        &__button2 {
            // display: flex;
            justify-content: center;
            // align-items: center;

            // height: 50px;

            padding-top: 40px;

            a {
                // display: flex;
                // text-decoration: none;

                span {
                    // display: block;

                    // font-family: 'Noto Sans';
                    // font-style: normal;
                    // font-weight: 400;
                    // font-size: 20px;
                    // line-height: 130%;
                    // color: #A0A8B3;
                }

                img {
                    // margin-right: 20px;

                    // display: block;
                    // width: 100px;
                }

                &:hover {
                    span {
                        // color: #002157;
                    }
                }
            }
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .projects2 {
        &__page {
            // max-width: 256px;
            width: 100%;

            padding: 100px 0;
            padding-left: 32px;
            padding-right: 32px;


        }

        &__heading {
            h2 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 22px;
                // line-height: 135%;
                // letter-spacing: 0.02em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__links {
            padding-top: 40px;

            // display: flex;
            // flex-direction: column;
            // overflow-x: scroll;

            span {
                // padding-right: 35px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 18px;
                // line-height: 130%;
            }

            &_inline {
                // overflow-x: scroll;

                height: 40px;
                width: 1200px;

                // display: flex;
                // align-items: center;
            }

            &_active {
                // color: #002157;
            }

            &_passive {
                // color: #A0A8B3;

                &:hover {
                    // cursor: pointer;
                    // color: #002157;
                }
            }
        }

        &__button1 {
            // height: 50px;

            // display: flex;
            // justify-content: space-between;
            // align-items: center;

            &_right,
            &_left {
                // cursor: pointer;
                width: 60px;

                img {
                    // width: 100%;
                    // height: auto;
                }
            }

            &_left {
                // transform: rotate(180deg);
            }
        }

        &__project {
            padding-top: 20px;

            // display: flex;
            // flex-direction: column;
        }

        &__slogan {
            // flex: 1 0 50%;

            padding-top: 25px;

            p {
                width: 100%;

                //     border-bottom: solid 1px black;
                //     border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 1;
                padding-bottom: 30px;

                //     font-family: 'OpiumNewC';
                //     font-style: normal;
                //     font-weight: 400;
                font-size: 16px;
                //     line-height: 160%;
                //     letter-spacing: 0.03em;
                //     font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                //     color: #38414D;
            }
        }

        &__text {
            // flex: 1 0 50%;

            // padding-top: 0px;

            p {
                width: 100%;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 14px;
                line-height: 155%;
                // letter-spacing: 0.01em;
                // color: #38414D;
            }
        }

        &__servicesheading {

            // padding-top: 60px;
            h3 {
                //     font-family: 'OpiumNewC';
                //     font-style: normal;
                //     font-weight: 400;
                font-size: 22px;
                //     line-height: 135%;
                //     letter-spacing: 0.02em;
                //     font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                //     color: #0F2538;
            }
        }

        &__servicesul {
            // padding-top: 40px;

            ul {
                width: 100%;
                padding-left: 20px;
                // display: flex;
                // flex-wrap: wrap;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 16px;
                // line-height: 120%;
                // color: #38414D;
            }

            li {
                width: 100%;
                // padding-bottom: 12px;
                // padding-right: 25px;
            }

            li:nth-child(2n + 1) {
                margin-right: 0px;
            }

            li:nth-child(2n) {
                // margin-right: 40px;
                width: auto;
            }
        }

        &__icon {
            flex: 1 0 50%;
            width: 256px;

            a {
                width: auto;
                height: 110px;

                display: flex;
                align-items: center;
            }

            img {
                width: 100%;
                height: auto;
            }
        }


        &__button2 {
            // display: flex;
            justify-content: flex-end;
            // align-items: center;

            // height: 50px;

            padding-top: 40px;

            a {
                // display: flex;
                // text-decoration: none;

                span {
                    // display: block;

                    // font-family: 'Noto Sans';
                    // font-style: normal;
                    // font-weight: 400;
                    // font-size: 20px;
                    // line-height: 130%;
                    // color: #A0A8B3;
                }

                img {
                    // margin-right: 20px;

                    // display: block;
                    // width: 100px;
                }

                &:hover {
                    span {
                        // color: #002157;
                    }
                }
            }
        }

    }
}
