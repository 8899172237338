// 
.vacancy3 {

    display: flex;
    justify-content: center;
    align-items: center;

    &__page {
        max-width: 1200px;
        flex: 1 0 100%;

        padding: 160px 0;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__heading1,
    &__heading2 {
        h2 {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 44px;
            line-height: 135%;
            letter-spacing: 0.02em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            color: #0F2538;
        }
    }

    &__text1,
    &__text2 {
        ul {
            padding-top: 40px;
            padding-left: 20px;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #38414D;

            li {
                height: 40px;
            }
        }
    }

    &__center {
        padding: 0 159px 0 169px;

        span {
            display: block;
            height: 280px;
            width: 1px;
            background-color: black;
            background: linear-gradient(to bottom, rgba(140, 154, 176, 0), rgba(140, 154, 176, 1), rgba(140, 154, 176, 0));
        }
    }
}

//1024 * 1919
@media (max-width: 1199px) {
    .vacancy3 {
        &__page {
            max-width: 924px;
            // flex: 1 0 100%;

            // padding: 160px 0;

            // display: flex;
            // justify-content: center;
            // align-items: center;
        }

        &__heading1,
        &__heading2 {
            h2 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 29px;
                // line-height: 135%;
                // letter-spacing: 0.02em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__center {
            padding: 0 60px 0 70px;

            span {
                // display: block;
                // height: 280px;
                // width: 1px;
                // background-color: black;
                // background: linear-gradient(to bottom, rgba(140, 154, 176, 0), rgba(140, 154, 176, 1), rgba(140, 154, 176, 0));
            }
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .vacancy3 {
        &__page {
            max-width: 400px;
            // flex: 1 0 100%;

            padding: 140px 0;

            // display: flex;
            flex-direction: column;
            // justify-content: center;
            align-items: flex-start;
        }

        &__heading1,
        &__heading2 {
            h2 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 25px;
                // line-height: 135%;
                // letter-spacing: 0.02em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__heading2 {
            padding-top: 56px;
        }

        &__text1,
        &__text2 {
            ul {
                padding-top: 30px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 14px;
                // line-height: 150%;
                // color: #38414D;

                li {
                    // height: 40px;
                }
            }
        }

        &__center {
            display: none;
            // padding: 0 75px 0 60px;

            span {
                // display: block;
                // height: 280px;
                // width: 1px;
                // background-color: black;
                // background: linear-gradient(to bottom, rgba(140, 154, 176, 0), rgba(140, 154, 176, 1), rgba(140, 154, 176, 0));
            }
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .vacancy3 {
        &__page {
            // max-width: 256px;
            max-width: 100%;
            // flex: 1 0 100%;

            padding: 100px 0;

            padding-left: 32px;
            padding-right: 32px;

            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            // align-items: flex-start;
        }

        &__heading1,
        &__heading2 {
            h2 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 22px;
                // line-height: 135%;
                // letter-spacing: 0.02em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__text1,
        &__text2 {
            ul {
                // padding-top: 30px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 14px;
                // line-height: 150%;
                // color: #38414D;

                li {
                    // height: 50px;
                    height: unset;
                    padding: 10px 0;
                }
            }
        }
    }
}