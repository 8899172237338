.section4 {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #161616;
    background: linear-gradient(to bottom right, #0a172d, #121416);

    &__iconswrapper {
        width: 1190px;
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        align-items: center;
    }

    &__icon-arrow {
        height: 154px;
        width: 590px;
    }

    &__icon {
        width: 295px;
        height: 154px;
        display: flex;
        justify-content: center;
        align-items: center;
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100px;
            width: 193px;
        }

        position: relative;

        border-right: 1px solid rgba(243, 248, 255, 0.15);

        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border-bottom: 1px solid rgba(243, 248, 255, 0.15);
        }
    }

    &__icon:nth-child(-n+4) {
        //первые 3
        border-image: linear-gradient(to top, rgba(243, 248, 255, 0.15), rgba(243, 248, 255, 0.0)) 0 1 0 0;
    }

    &__icon:nth-child(4n) {
        //каждый третий
        border-right: unset;

        &::before {
            border-image: linear-gradient(to right, rgba(243, 248, 255, 0.15), rgba(243, 248, 255, 0.0)) 0 0 1 0;
        }
    }

    &__icon:nth-child(4n+1) {
        //каждый третий начиная с первого
        &::before {
            border-image: linear-gradient(to left, rgba(243, 248, 255, 0.15), rgba(243, 248, 255, 0.0)) 0 0 1 0;
        }
    }

    &__icon:nth-child(n+13):nth-child(-n+14) {
        //с 7 по 9
        border-image: linear-gradient(to bottom, rgba(243, 248, 255, 0.15), rgba(243, 248, 255, 0.0)) 0 1 0 0;

        &::before {
            border-bottom: unset;
        }
    }
}

//1024 * 1919
// @media (max-width: 1919px) {
@media (max-width: 1500px) {
    .section4 {
        &__icon {
            width: 250px;
            height: 170px;
        }
    }
}

//1024 * 1919
// @media (max-width: 1919px) {
@media (max-width: 1200px) {
    .section4 {
        &__icon {
            width: 236px;
            height: 156px;
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .section4 {
        &__iconswrapper {
            width: 450px;

            justify-content: left;
        }

        &__icon {
            width: 50%;
            height: 130px;


        }


        &__icon:nth-child(-n+3) {
            //первые 3
            // border-image: linear-gradient(to top, rgba(243, 248, 255, 15), rgba(243, 248, 255, 0.0)) 0 1 0 0;
            border-image: none;
        }
        &__icon:nth-child(3n) {
            //каждый третий
            // border-right: unset;
            border-right: 1px solid rgba(243, 248, 255, 0.15);

            &::before {
                // border-image: linear-gradient(to right, rgba(243, 248, 255, 15), rgba(243, 248, 255, 0.0)) 0 0 1 0;
                border-image: none;
            }
        }
        &__icon:nth-child(3n+1) {
            //каждый третий начиная с первого
            &::before {
                // border-image: linear-gradient(to left, rgba(243, 248, 255, 15), rgba(243, 248, 255, 0.0)) 0 0 1 0;
                border-image: none;
            }
        }
        &__icon:nth-child(n+7):nth-child(-n+9) {
            //с 7 по 9
            // border-image: linear-gradient(to bottom, rgba(243, 248, 255, 15), rgba(243, 248, 255, 0.0)) 0 1 0 0;
            border-image: none;

            &::before {
                // border-bottom: unset;
                border-bottom: 1px solid rgba(243, 248, 255, 0.15);
            }
        }


        &__icon:nth-child(2n) {
            //каждый 2
            border-right: unset;

            &::before {
                border-image: linear-gradient(to right, rgba(243, 248, 255, 0.15), rgba(243, 248, 255, 0.0)) 0 0 1 0;
            }
        }

        &__icon:nth-child(2n+1) {
            //каждый 2 начиная с первого
            &::before {
                border-image: linear-gradient(to left, rgba(243, 248, 255, 0.15), rgba(243, 248, 255, 0.0)) 0 0 1 0;
            }
        }

        &__icon:nth-child(1) {
            border-image: linear-gradient(to top, rgba(243, 248, 255, 0.15), rgba(243, 248, 255, 0.0)) 0 1 0 0;
        }

        &__icon:nth-child(n+9):nth-child(-n+9) {
            border-image: linear-gradient(to bottom, rgba(243, 248, 255, 0.15), rgba(243, 248, 255, 0.0)) 0 1 0 0;

            &::before {
                border-bottom: unset;
            }
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .section4 {
        flex-direction: column;
        justify-content: flex-start;
        overflow-x: scroll;

        &__iconswrapper {
            // width: 256px;
            width: auto;

            padding-left: 32px;
            padding-right: 32px;

            padding-top: 150px;
        }

        &__icon {
            // width: 50%;
            height: 83px;
            // height: 60%;

            a {
                img {
                    width: 80%;
                }
            }
        }
    }
}
