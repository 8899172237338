//
.link {
    display: flex;
    text-decoration: none;
    cursor: pointer;

    span {
        display: block;
        transition: color 0.3s linear 0s;

        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 130%;
        color: #A0A8B3;
    }

    img {
        margin-right: 20px;

        display: block;
        width: 100px;
    }

    &:hover {
        span {
            color: #002157;
        }
    }
}

//1024 * 1919
@media (max-width: 1919px) {}

//768 * 1023
@media(max-width: 1023px) {
    .link {
        img {
            width: 60px;
        }

        span {
            font-size: 18px;
        }
    }
}

//320 * 767
@media (max-width: 767px) {}