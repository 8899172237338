// 
.company2 {
    display: flex;
    justify-content: center;

    &__page {
        max-width: 1200px;
        flex: 1 0 100%;

        height: max-content;
    }

    &__content1 {
        display: flex;

        padding-top: 160px;
    }

    h2 {
        flex: 1 0 50%;

        font-family: 'OpiumNewC';
        font-style: normal;
        font-weight: 400;
        font-size: 84px;
        line-height: 122%;
        letter-spacing: 0.01em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
        color: #0F2538;
    }

    &__text1 {
        position: relative;
        top: 30px;

        border-bottom: 1px solid;
        border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05))1;
        padding-bottom: 40px;

        p {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 160%;
            letter-spacing: 0.03em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            color: #38414D;
        }
    }

    &__content2 {
        position: relative;
        top: -15px;

        padding-bottom: 130px;
    }

    h3 {
        font-family: 'OpiumNewC';
        font-style: normal;
        font-weight: 400;
        font-size: 44px;
        line-height: 135%;
        letter-spacing: 0.02em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
        color: #0F2538;
    }

    &__text2 {
        width: 460px;
        padding-top: 40px;

        p {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 160%;
            letter-spacing: 0.03em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            color: #38414D;
        }
    }
}

//1700 * 1919
@media (max-width: 1919px) {
    .company2 {
        h2 {
            // flex: 1 0 50%;

            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 77px;
            // line-height: 122%;
            // letter-spacing: 0.01em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #0F2538;
        }

        &__text1 {
            // position: relative;
            top: 25px;

            // border-bottom: 1px solid;
            // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05))100;
            // padding-bottom: 40px;

            p {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 21px;
                // line-height: 160%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #38414D;
            }
        }

        &__content2 {
            // position: relative;
            top: -17px;
    
            // padding-bottom: 130px;
        }

        h3 {
            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 40px;
            // line-height: 135%;
            // letter-spacing: 0.02em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #0F2538;
        }

        &__text2 {
            width: 480px;
            // padding-top: 40px;

            p {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 21px;
                // line-height: 160%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #38414D;
            }
        }
    }
}

//1280 * 1699
@media (max-width: 1699px) {
    .company2 {
        &__page {
            max-width: 940px;
            // flex: 1 0 100%;

            // height: max-content;
        }

        h2 {
            // flex: 1 0 50%;

            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 63px;
            // line-height: 122%;
            // letter-spacing: 0.01em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #0F2538;
        }

        &__text1 {
            // position: relative;
            top: 20px;

            // border-bottom: 1px solid;
            // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05))100;
            // padding-bottom: 40px;

            p {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 19px;
                // line-height: 160%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #38414D;
            }
        }

        &__content2 {
            // position: relative;
            top: -14px;
    
            // padding-bottom: 130px;
        }

        h3 {
            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 33px;
            // line-height: 135%;
            // letter-spacing: 0.02em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #0F2538;
        }

        &__text2 {
            width: 380px;
            // padding-top: 40px;

            p {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 19px;
                // line-height: 160%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #38414D;
            }
        }
    }
}

//1024 * 1279
@media (max-width: 1279px) {
    .company2 {
        h2 {
            // flex: 1 0 50%;

            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 55px;
            // line-height: 122%;
            // letter-spacing: 0.01em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #0F2538;
        }

        &__text1 {
            // position: relative;
            top: 17px;

            // border-bottom: 1px solid;
            // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05))100;
            // padding-bottom: 40px;

            p {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 18px;
                // line-height: 160%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #38414D;
            }
        }

        h3 {
            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 29px;
            // line-height: 135%;
            // letter-spacing: 0.02em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #0F2538;
        }

        &__text2 {
            // width: 375px;
            // padding-top: 40px;

            p {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 18px;
                // line-height: 160%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #38414D;
            }
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .company2 {
        &__page {
            max-width: 668px;
            // flex: 1 0 100%;

            // height: max-content;
        }

        &__content1 {
            // display: flex;

            padding-top: 140px;
        }

        h2 {
            // flex: 1 0 50%;

            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 47px;
            // line-height: 122%;
            // letter-spacing: 0.01em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #0F2538;
        }

        &__text1 {
            // position: relative;
            top: 15px;


            // border-bottom: 1px solid;
            // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05))100;
            // padding-bottom: 40px;

            p {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 17px;
                line-height: 145%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #38414D;
            }
        }

        &__content2 {
            // position: relative;
            top: -11px;

            padding-bottom: 120px;
        }

        h3 {
            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 24px;
            // line-height: 135%;
            // letter-spacing: 0.02em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #0F2538;
        }

        &__text2 {
            width: 324px;
            // padding-top: 40px;

            p {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 17px;
                // line-height: 160%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #38414D;
            }
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .company2 {
        &__page {
            max-width: 256px;
            max-width: 100%;
            padding-left: 32px;
            padding-right: 32px;
            // flex: 1 0 100%;

            // height: max-content;
        }

        &__content1 {
            // display: flex;
            flex-direction: column;

            padding-top: 100px;
        }

        h2 {
            // flex: 1 0 50%;

            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 32px;
            // line-height: 122%;
            // letter-spacing: 0.01em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #0F2538;
        }

        &__text1 {
            // position: relative;
            top: 0px;

            // border-bottom: 1px solid;
            // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05))100;
            padding-bottom: 32px;
            padding-top: 48px;

            p {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 17px;
                // line-height: 145%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #38414D;
            }
        }

        &__content2 {
            // position: relative;
            top: 0px;

            padding-top: 42px;
            padding-bottom: 100px;
        }

        h3 {
            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 22px;
            // line-height: 135%;
            // letter-spacing: 0.02em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #0F2538;
        }

        &__text2 {
            // width: 256px;
            width: 100%;
            padding-top: 32px;

            p {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 17px;
                // line-height: 160%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #38414D;
            }
        }
    }
}