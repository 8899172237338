.section5 {
    display: flex;
    align-items: center;
    background: linear-gradient(to bottom right, #0a172d, #121416);

    &__bg1 {
        position: absolute;
        height: 100%;
        width: 150%;
        background: url(../../../../images/section5BG1.jpg) right no-repeat;
        background-size: contain;
        right: -50px;
        transition: none;
        transform: perspective(200px) translateZ(20px);
        opacity: 0;

        &--enter {
            transition: transform 3s ease-out, opacity 3s ease-out;
            transform: perspective(200px) translateZ(0px);
            opacity: 1;
        }
    }

    &__bg2 {
        position: absolute;
        width: 100%;
        height: 100%;

        background: url(../../../../images/section5BG2.svg) center no-repeat;
        background-size: cover;
    }

    &__content {
        height: 352px;
        width: 410px;
        position: relative;
        // top: 320px;
        left: 390px;

        display: flex;
        flex-direction: column;
    }

    &__textline {
        display: flex;
        align-items: center;

        padding-bottom: 10px;
    }

    &__text {
        position: relative;
        left: -12px;
        top: -9px;

        padding-bottom: 70px;

        span {
            display: block;
        }

        &_small {
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 180%;
            letter-spacing: 0.01em;
            color: #F5F5F5;
        }

        &_big {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 120px;
            line-height: 100%;
            text-align: center;
            letter-spacing: 0.03em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #F5F5F5;
        }

        &_midle {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 700;
            font-size: 55px;
            line-height: 80%;
            letter-spacing: 0.03em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #F5F5F5;
        }

        &_space {
            padding-right: 8px;
        }
    }
}

//1024 * 1919
// @media (max-width: 1919px) {
@media (max-width: 1199px) {
    .section5 {
        //min-height: 653px;

        &__bg1 {
            position: absolute;
            height: 100%;
            width: 200%;
            background: url(../../../../images/section5BG1.jpg) right no-repeat;
            background-size: contain;

            right: -300px;
        }

        &__content {
            height: 352px;
            width: 410px;

            // top: 220px;
            left: 180px;
        }

        &__text {
            padding-bottom: 40px;

            &_big {
                font-size: 79px;
            }

            &_midle {
                font-size: 36px;
            }
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .section5 {
        //min-height: 876px;

        &__bg1 {
            position: absolute;
            height: 100%;
            width: 200%;
            background: url(../../../../images/section5BG1.jpg) right no-repeat;
            background-size: contain;

            right: -400px;
        }

        &__content {
            // top: 321px;
            left: 160px;
        }

        &__textline {
            padding-bottom: 6px;
        }

        &__text {
            padding-bottom: 40px;

            &_big {
                font-size: 69px;
            }

            &_midle {
                font-size: 31px;
            }
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .section5 {
        //min-height: 568px;

        display: flex;
        justify-content: center;

        &__bg1 {
            position: absolute;
            height: 100%;
            width: 100%;
            background: url(../../../../images/section5BG3.png) center no-repeat;
            background-size: cover;

            right: 0px;
            left: 0px;
        }

        &__bg2 {
            display: none;
        }

        &__content {
            // top: 194px;
            left: 32px;

            // width: 296px;
            width: 100%;
            padding-left: 14px;

            justify-content: center;
            // align-items: flex-start;
        }

        &__text {
            padding-bottom: 20px;

            &_big {
                font-size: 60px;
            }

            &_midle {
                font-size: 27px;
            }

            &_small {
                font-size: 14px;
            }
        }
    }
}
