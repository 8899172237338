.h1 {
    font-family: 'OpiumNewC';
    font-style: normal;
    font-weight: 400;
    font-size: 58px;
    line-height: 130%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #F5F5F5;
}

//1700 * 1919
@media (max-width: 1919px) {
    .h1 {
        // font-family: 'OpiumNewC';
        // font-style: normal;
        // font-weight: 400;
        font-size: 53px;
        // line-height: 130%;
        // font-feature-settings: 'pnum' on, 'lnum' on;
        // color: #F5F5F5;
    }
}

//1280 * 1699
@media (max-width: 1699px) {
    .h1 {
        // font-family: 'OpiumNewC';
        // font-style: normal;
        // font-weight: 400;
        font-size: 44px;
        // line-height: 130%;
        // font-feature-settings: 'pnum' on, 'lnum' on;
        // color: #F5F5F5;
    }
}

//1024 * 1279
@media (max-width: 1279px) {
    .h1 {
        // font-family: 'OpiumNewC';
        // font-style: normal;
        // font-weight: 400;
        font-size: 38px;
        // line-height: 130%;
        // font-feature-settings: 'pnum' on, 'lnum' on;
        // color: #F5F5F5;
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .h1 {
        // font-family: 'OpiumNewC';
        // font-style: normal;
        // font-weight: 400;
        font-size: 33px;
        // line-height: 130%;
        // font-feature-settings: 'pnum' on, 'lnum' on;
        // color: #F5F5F5;
    }
}

//320 * 767
@media (max-width: 767px) {
    .h1 {
        // font-family: 'OpiumNewC';
        // font-style: normal;
        // font-weight: 400;
        font-size: 26px;
        // line-height: 130%;
        // font-feature-settings: 'pnum' on, 'lnum' on;
        // color: #F5F5F5;
    }
}