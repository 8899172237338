//
.active {
    transform: rotate(180deg);
}

.arrow {
    z-index: 999;
    cursor: pointer;
    position: fixed;
    right: 50px;
    bottom: 80px;

    height: 8px;
    width: 80px;

    display: flex;
    align-items: center;

    img {
        display: block;
        height: 8px;
        width: auto;

        transition: transform 0.3s linear;
    }

    span {
        padding-left: 10px;

        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: #A0A8B3;

        transition: color 0.3s linear;

        &:hover {
            color: #d3d3d3;
        }
    }
}

//1700 * 1919
@media (max-width: 1919px) {
    .arrow {
        // right: 108px;
        right: 45px;
        bottom: 80px;
    }
}

//1280 * 1699
@media (max-width: 1699px) {
    .arrow {
        // right: 50px;
        // bottom: 248px;
        // bottom: 80px;
    }
}

//1024 * 1279
@media (max-width: 1279px) {
    .arrow {
        // right: 50px;
        // bottom: 255px;
        bottom: 70px;


    }
}

//768 * 1023
@media(max-width: 1023px) {
    .arrow {
        width: 15px;
        right: 50px;
        // bottom: 500px;
        // bottom: 70px;

        span {
            display: none;
            // padding-left: 5px;

            // font-family: 'Noto Sans';
            // font-style: normal;
            // font-weight: 500;
            // font-size: 12px;
            // line-height: 16px;
            // letter-spacing: 0.07em;
            // text-transform: uppercase;
            // color: #A0A8B3;
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .arrow {
        right: 31px;
        // bottom: 538px;
        bottom: 50px;
    }
}