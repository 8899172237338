.gallerymodal {
    z-index: 1000;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);

    display: flex;
    justify-content: center;
    align-items: center;

    &__imgwrapper {
        height: 100%;
        // padding: 20px 0px;

        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
            display: block;
            height: 100%;
            width: auto;
            padding: 10px 32px;
        }
    }

    &__closebutton {
        position: absolute;
        background-color: unset;

        height: 30px;
        width: 30px;

        cursor: pointer;
        top: 15px;
        right: 15px;

        img {
            height: 100%;
            width: auto;
        }
    }

    &__rightbutton,
    &__leftbutton {
        cursor: pointer;
        position: absolute;
        background-color: unset;

        height: 40px;
        width: 40px;

        img {
            height: 100%;
            width: auto;
        }
    }

    &__rightbutton {
        right: 0;
    }

    &__leftbutton {
        left: 0;
        transform: rotate(180deg);
    }

    &__button_2 {
        display: none;
    }
}

//320 * 767
@media (max-width: 767px) {
    .gallerymodal {

        &__imgwrapper {
            // height: 100%;
            // padding: 20px 0;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                // display: block;
                height: auto;
                width: auto;
                max-width: 100vw;
            }
        }

        &__closebutton {
            // position: absolute;
            // background-color: rgb(0, 0, 0, 0.1);

            // height: 30px;
            // width: 30px;

            // cursor: pointer;
            // top: 15px;
            // right: 15px;

            img {
                // height: 100%;
                // width: auto;
            }
        }

        &__rightbutton,
        &__leftbutton {
            display: none;
            background-color: rgb(0, 0, 0, 0.1);
            // cursor: pointer;
            // position: absolute;
            // background-color: unset;

            // height: 40px;
            // width: 40px;

            img {
                // height: 100%;
                // width: auto;
            }
        }

        &__button_2 {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        &__rightbutton_2,
        &__leftbutton_2 {
            cursor: pointer;
            background-color: unset;
    
            // height: 40px;
            // width: 40px;
    
            img {
                // height: 100%;
                // width: auto;
            }
        }
    
        &__rightbutton_2 {

        }
    
        &__leftbutton_2 {

            transform: rotate(180deg);
    
        }
    }
}