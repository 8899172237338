.partners2 {
    height: 719px;
    background-color: #F8F8F8;

    display: flex;
    justify-content: center;
    align-items: center;

    &__page {
        max-width: 1200px;
        flex: 1 0 100%;
    }

    &__part1 {
        display: flex;
    }

    &__heading {
        width: 50%;

        h2 {
            position: relative;
            top: -30px;


            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 84px;
            line-height: 122%;
            letter-spacing: 0.01em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            color: #0F2538;
        }
    }

    &__content1 {
        width: 40%;

        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &__text1 {
        p {
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 180%;
            letter-spacing: 0.01em;
            color: #38414D;
        }
    }

    &__link {
        padding-top: 40px;
        position: relative;
        top: 15px;

        a {
            text-decoration: none;
        }

        span {
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 130%;
            color: #A0A8B3;
        }

        img {
            padding-right: 21px;
        }

        &:hover {
            span {
                color: #002157;
            }
        }
    }

    &__part2 {
        display: flex;
    }

    &__content2 {
        width: 40%;
    }

    &__line {
        padding-bottom: 40px;
    }

    &__text2 {
        font-family: 'OpiumNewC';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 160%;
        letter-spacing: 0.03em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
        color: #38414D;
    }
}


//1024 * 1919
// @media (max-width: 1919px) {
@media (max-width: 1199px) {
    .partners2 {
        height: 773px;

        &__page {
            max-width: 925px;
            // flex: 1 0 100%;
        }

        &__heading {
            // width: 50%;

            h2 {
                // position: relative;
                top: -15px;

                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 55px;
                // line-height: 122%;
                // letter-spacing: 0.01em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__text2 {
            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 18px;
            // line-height: 160%;
            // letter-spacing: 0.03em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #38414D;
        }

    }
}

//768 * 1023
@media(max-width: 1023px) {
    .partners2 {
        height: 683px;

        &__page {
            max-width: 668px;
            // flex: 1 0 100%;
        }

        &__heading {
            // width: 50%;

            h2 {
                // position: relative;
                top: -13px;

                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 47px;
                // line-height: 122%;
                // letter-spacing: 0.01em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__content1 {
            width: 50%;

            // display: flex;
            // flex-direction: column;
            // align-items: flex-end;
        }

        &__text1 {
            p {
                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 15px;
                line-height: 160%;
                // letter-spacing: 0.01em;
                // color: #38414D;
            }
        }

        &__link {
            a {}

            span {
                font-size: 18px;
            }

            img {
                padding-right: 21px;
            }
        }

        &__content2 {
            width: 50%;
        }

        &__text2 {
            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 17px;
            line-height: 145%;
            // letter-spacing: 0.03em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #38414D;
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .partners2 {
        height: 834px;

        &__page {
            // max-width: 265px;
            width: 100%;
            padding-left: 32px;
            padding-right: 32px;
            // flex: 1 0 100%;
        }

        &__part1 {
            // display: flex;
            flex-direction: column;
        }

        &__heading {
            width: 100%;

            h2 {
                // position: relative;
                top: 0px;

                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 32px;
                // line-height: 122%;
                // letter-spacing: 0.01em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__content1 {
            width: 100%;

            // display: flex;
            // flex-direction: column;
            // align-items: flex-end;
        }

        &__text1 {
            padding-top: 40px;

            p {
                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 14px;
                line-height: 155%;
                // letter-spacing: 0.01em;
                // color: #38414D;
            }
        }

        &__link {
            a {}

            span {}

            img {
                padding-right: 21px;
                width: 100px;
            }
        }

        &__content2 {
            width: 100%;
        }

        &__line {
            padding-top: 70px;
            padding-bottom: 30px;
        }
    }
}