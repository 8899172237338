.section1 {
    background: url(../../../../images/section1BG1_1.jpg) center no-repeat; //задний фон город
    background-size: cover;

    h1 {
        z-index: 2;
        display: block;
        position: absolute;

        padding-left: 144px;
        bottom: 166px;

        font-family: 'OpiumNewC';
        font-style: normal;
        font-weight: 400;
        font-size: 84px;
        line-height: 122%;
        letter-spacing: 0.01em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;

        color: #F5F5F5;
    }

    &__bottomborder {
        //нижняя отбивка, линия
        position: absolute;
        display: block;
        bottom: 70px;

        height: 1px;
        width: 1080px;
        background: linear-gradient(to right, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.02));
    }

    &__background2 {
        //слой задний фон большая шестеренка
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../../../../images/section1BG2.svg) no-repeat center;
        background-size: cover;
        transition: none;
        transform: perspective(200px) translateZ(20px);

        &--enter {
            transition: transform 2s ease-out;
            transform: perspective(200px) translateZ(0px);
        }
    }

    &__background3 {
        //слой задний фон под меню
        z-index: 1;
        position: absolute;
        top: 0px;
        height: 260px;
        width: 100%;
        background: url(../../../../images/section1BG3.svg) no-repeat top;
        background-size: 100%;
    }
}

//1024 * 1919
    @media (max-width: 1190px) {
    .section1 {
        h1 {
            padding-left: 50px;
            bottom: 123px;

            font-size: 55px;
            line-height: 120%;
        }

        &__bottomborder {
            width: 683px;
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .section1 {
        h1 {
            bottom: 100px;

            font-size: 48px;
        }

        &__bottomborder {
            bottom: 60px;

            width: 485px;
        }

        &__background3 {
            display: none;
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .section1 {
        h1 {
            padding-left: 31px;
            bottom: 88px;

            font-size: 32px;
        }

        &__bottomborder {
            bottom: 48px;

            width: 245px;
        }
    }
}
