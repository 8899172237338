.section7 {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #161616;
    background: linear-gradient(to bottom right, #0C121A99 60%, #07152BB2 70%, #061733E5 90%, #021533);

    &__iconswrapper {
        width: 1180px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    &__icon {
        width: 295px;
        height: 153px;

        &_wrapper {
            height: 100%;
            width: 100%;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        position: relative;
        border-right: 1px solid rgba(243, 248, 255, 0.15);

        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border-bottom: 1px solid rgba(243, 248, 255, 0.15);
        }
    }

    &__icon:nth-child(-n+4) {
        //первые 4
        border-image: linear-gradient(to top, rgba(243, 248, 255, 0.15), rgba(243, 248, 255, 0.0)) 0 1 0 0;
    }

    &__icon:nth-child(4n) {
        //каждый 4
        border-right: unset;

        &::before {
            border-image: linear-gradient(to right, rgba(243, 248, 255, 0.15), rgba(243, 248, 255, 0.0)) 0 0 1 0;
        }
    }

    &__icon:nth-child(4n+1) {

        //каждый 4 начиная с первого
        &::before {
            border-image: linear-gradient(to left, rgba(243, 248, 255, 0.15), rgba(243, 248, 255, 0.0)) 0 0 1 0;
        }
    }

    &__icon:nth-child(n+13):nth-child(-n+16) {
        //с 13 по 16
        border-image: linear-gradient(to bottom, rgba(243, 248, 255, 0.15), rgba(243, 248, 255, 0.0)) 0 1 0 0;

        &::before {
            border-bottom: unset;
        }
    }
}

//1024 * 1919
// @media (max-width: 1919px) {
@media (max-width: 1700px) {
    .section7 {
        &__iconswrapper {
            width: 1000px;
            // display: flex;
            // flex-wrap: wrap;
            justify-content: flex-start;
            // align-items: center;
        }

        &__icon {
            width: 250px;
            height: 125px;

            img {
                height: 80%;
                width: auto;
            }
        }

    }
}

//1024 * 1919
// @media (max-width: 1919px) {
@media (max-width: 1500px) {
    .section7 {
        &__iconswrapper {
            width: 760px;
            // display: flex;
            // flex-wrap: wrap;
            justify-content: flex-start;
            // align-items: center;
        }

        &__icon {
            width: 190px;
            height: 95px;

            img {
                height: 80%;
                width: auto;
            }
        }

    }
}

//1024 * 1919
// @media (max-width: 1919px) {
@media (max-width: 1199px) {
    .section7 {
        &__iconswrapper {
            width: 680px;
            // display: flex;
            // flex-wrap: wrap;
            justify-content: flex-start;
            // align-items: center;
        }

        &__icon {
            width: 160px;
            height: 80px;

            img {
                height: 80%;
                width: auto;
            }
        }

    }
}

//768 * 1023
@media(max-width: 1023px) {
    .section7 {
        &__iconswrapper {
            width: 480px;
            // display: flex;
            // flex-wrap: wrap;
            justify-content: flex-start;
            // align-items: center;
        }

        &__icon {
            width: 160px;
            height: 80px;

            img {
                height: 80%;
                width: auto;
            }
        }



        &__icon:nth-child(-n+4) {
            //первые 4
            // border-image: linear-gradient(to top, rgba(243, 248, 255, 15), rgba(243, 248, 255, 0.0)) 0 1 0 0;
            border-image: none;
        }

        &__icon:nth-child(4n) {
            //каждый 4
            // border-right: unset;
            border-right: 1px solid rgba(243, 248, 255, 0.15);

            &::before {
                // border-image: linear-gradient(to right, rgba(243, 248, 255, 15), rgba(243, 248, 255, 0.0)) 0 0 1 0;
                border-image: none;
            }
        }

        &__icon:nth-child(4n+1) {

            //каждый 4 начиная с первого
            &::before {
                // border-image: linear-gradient(to left, rgba(243, 248, 255, 15), rgba(243, 248, 255, 0.0)) 0 0 1 0;
                border-image: none;
            }
        }

        &__icon:nth-child(n+13):nth-child(-n+16) {
            //с 13 по 16
            // border-image: linear-gradient(to bottom, rgba(243, 248, 255, 15), rgba(243, 248, 255, 0.0)) 0 1 0 0;
            border-image: none;

            &::before {
                // border-bottom: unset;
                border-bottom: 1px solid rgba(243, 248, 255, 0.15);
            }
        }


        &__icon:nth-child(-n+3) {
            //первые 3
            border-image: linear-gradient(to top, rgba(243, 248, 255, 0.15), rgba(243, 248, 255, 0.0)) 0 1 0 0;
        }

        &__icon:nth-child(3n) {
            //каждый третий
            border-right: unset;

            &::before {
                border-image: linear-gradient(to right, rgba(243, 248, 255, 0.15), rgba(243, 248, 255, 0.0)) 0 0 1 0;
            }
        }

        &__icon:nth-child(3n+1) {

            //каждый третий начиная с первого
            &::before {
                border-image: linear-gradient(to left, rgba(243, 248, 255, 0.15), rgba(243, 248, 255, 0.0)) 0 0 1 0;
            }
        }

        &__icon:nth-child(n+16):nth-child(-n+16) {
            //с 7 по 9
            border-image: linear-gradient(to bottom, rgba(243, 248, 255, 0.15), rgba(243, 248, 255, 0.0)) 0 1 0 0;

            &::before {
                border-bottom: unset;
            }
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .section7 {

        align-items: flex-start;

        &__iconswrapper {
            // width: 256px;
            width: 100%;
            padding-left: 30px;
            padding-right: 30px;

            position: relative;
            top: 80px;


            // display: flex;
            // flex-wrap: wrap;
            // justify-content: flex-start;
            // align-items: center;

            padding-top: 70px;
        }

        &__icon {
            // width: 128px;
            width: 50%;
            height: 57px;

            img {
                height: 130%;
                width: auto;

                // height: auto;
                // width: 80%;
            }
        }


        &__icon:nth-child(-n+3) {
            //первые 3
            // border-image: linear-gradient(to top, rgba(243, 248, 255, 15), rgba(243, 248, 255, 0.0)) 0 1 0 0;
            border-image: none;
        }

        &__icon:nth-child(3n) {
            //каждый третий
            // border-right: unset;
            border-right: 1px solid rgba(243, 248, 255, 0.15);

            &::before {
                // border-image: linear-gradient(to right, rgba(243, 248, 255, 15), rgba(243, 248, 255, 0.0)) 0 0 1 0;
                border-image: none;
            }
        }

        &__icon:nth-child(3n+1) {

            //каждый третий начиная с первого
            &::before {
                // border-image: linear-gradient(to left, rgba(243, 248, 255, 15), rgba(243, 248, 255, 0.0)) 0 0 1 0;
                border-image: none;
            }
        }

        &__icon:nth-child(n+16):nth-child(-n+16) {
            //с 7 по 9
            // border-image: linear-gradient(to bottom, rgba(243, 248, 255, 15), rgba(243, 248, 255, 0.0)) 0 1 0 0;
            border-image: none;

            &::before {
                // border-bottom: unset;
                border-bottom: 1px solid rgba(243, 248, 255, 0.15);
            }
        }


        &__icon:nth-child(2n) {
            //каждый 2
            border-right: unset;

            &::before {
                border-image: linear-gradient(to right, rgba(243, 248, 255, 0.15), rgba(243, 248, 255, 0.0)) 0 0 1 0;
            }
        }

        &__icon:nth-child(2n+1) {

            //каждый 2 начиная с первого
            &::before {
                border-image: linear-gradient(to left, rgba(243, 248, 255, 0.15), rgba(243, 248, 255, 0.0)) 0 0 1 0;
            }
        }

        &__icon:nth-child(1) {
            border-image: linear-gradient(to top, rgba(243, 248, 255, 0.15), rgba(243, 248, 255, 0.0)) 0 1 0 0;
        }

        &__icon:nth-child(n+15):nth-child(-n+16) {
            border-image: linear-gradient(to bottom, rgba(243, 248, 255, 0.15), rgba(243, 248, 255, 0.0)) 0 1 0 0;

            &::before {
                border-bottom: unset;
            }
        }
    }
}
