// 
.contacts2 {
    min-height: 432px;

    background-color: #F8F8F8;

    &__page {
        max-width: 1200px;

        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }

    &__write {
        position: relative;

        padding-top: 160px;

        span {
            font-family: 'OpiumNewC';
            font-style: italic;
            font-weight: 400;
            font-size: 24px;
            line-height: 122%;
            letter-spacing: 0.03em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #002157;
        }
    }

    &__link {
        position: absolute;
        right: 0;

        padding-top: 66px;

        a {
            text-decoration: none;
        }

        span {
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 130%;
            color: #A0A8B3;
        }

        img {
            padding-right: 21px;
        }

        &:hover {
            span {
                color: #002157;
            }
        }
    }

    &__phone {
        display: flex;
        flex-direction: column;

        padding-top: 160px;
        //padding-bottom: 40px;

        span {
            &:nth-child(1) {
                font-family: 'OpiumNewC';
                font-style: normal;
                font-weight: 400;
                font-size: 58px;
                line-height: 130%;
                font-feature-settings: 'pnum' on, 'lnum' on;
                color: #0F2538;

                a{
                    text-decoration: none;
                    color: #0F2538;
                }
            }

            &:nth-child(2) {
                padding-top: 10px;

                font-family: 'Noto Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 95%;
                color: #38414D;
            }

            &:nth-child(3) {
                margin-top: 40px;

                display: block;
                height: 1px;
                background: linear-gradient(to right, rgba(165, 173, 184, 0.4) 0%, rgba(165, 173, 184, 0.05) 100%);
            }
        }
    }
}

//1024 * 1919
@media (max-width: 1199px) {
    .contacts2 {
        &__page {
            max-width: 915px;
        }

        &__write {
            // position: relative;
    
            // padding-top: 160px;
    
            span {
                // font-family: 'OpiumNewC';
                // font-style: italic;
                // font-weight: 400;
                font-size: 20px;
                // line-height: 122%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;
            }
        }

        &__link {
            // position: absolute;
            // right: 0;
    
            padding-top: 40px;
    
            a {
                // text-decoration: none;
            }
    
            span {
                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 20px;
                // line-height: 130%;
                // color: #A0A8B3;
            }
    
            img {
                // padding-right: 21px;
            }
    
            &:hover {
                span {
                    // color: #002157;
                }
            }
        }

        &__phone {
            span {
                &:nth-child(1) {
                    font-size: 38px;
                    padding-right: 68px;
                }
            }
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .contacts2 {
        &__page {
            min-height: 495px;
            flex-direction: column-reverse;

            padding: 0 50px;
        }

        &__phone {
            display: flex;
            flex-direction: column;

            padding-top: 140px;
            //padding-bottom: 40px;

            span {
                &:nth-child(1) {
                    font-size: 32px;
                }

                &:nth-child(2) {
                    padding-top: 8px;
                }

                &:nth-child(3) {
                    margin-top: 32px;
                }
            }
        }

        &__write {
            padding-top: 0px;
            display: flex;

            span:nth-child(1) {
                margin-bottom: 150px;
                display: block;

                font-size: 18px;
            }
        }

        &__link {
            padding-top: 0px;
            top: 90px;
            left: 60px;

            a {}

            span {
                font-size: 18px;
            }

            img {
                padding-right: 21px;
            }
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .contacts2 {
        &__page {
            min-height: 422px;
            padding: 0 32px;

            justify-content: flex-start;
        }

        &__phone {
            padding-top: 100px;

            span {
                &:nth-child(1) {
                    padding-right: 0px;
                    font-size: 26px;
                }

                &:nth-child(2) {
                    font-size: 14px;
                    padding-top: 8px;
                }

                &:nth-child(3) {
                    margin-top: 32px;
                }
            }
        }

        &__write {
            padding-top: 0px;
            display: flex;

            span:nth-child(1) {
                padding-top: 50px;
                font-size: 16px;
            }
        }

        &__link {
            padding-top: 0px;
            top: 140px;
            left: 0px;

            a {}

            span {}

            img {
                padding-right: 21px;
                width: 100px;
            }
        }
    }
}