// 
.partners9 {
    display: flex;
    justify-content: center;

    &__page {
        max-width: 930px;
        flex: 1 0 100%;
    }

    &__gallery {
        display: flex;
        flex-wrap: wrap;

        padding-top: 160px;
        padding-bottom: 190px;
    }

    &__brand {
        width: 310px;
        height: 210px;

        display: flex;
        justify-content: center;
        align-items: center;

        border-right: 1px solid#F3F8FF;
        border-bottom: 1px solid #F3F8FF;
    }

    &__brand:nth-child(3n) {
        border-right: unset;
    }

    &__brand:nth-child(n+7) {
        border-bottom: unset;
    }

    &__brandwrapper {
        width: 160px;
        height: 85px;

        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: 100%;
            width: auto;

            position: absolute;
        }
    }

    &__brand_active {}

    &__brand_passive {
        opacity: 1;
        transition: 0.3s;

        &:hover {
            opacity: 0;
        }
    }
}

//1024 * 1919
@media (max-width: 1919px) {
    .partners9 {}
}

//768 * 1023
@media(max-width: 1023px) {
    .partners9 {
        &__page {
            max-width: 620px;
            // flex: 1 0 100%;
        }

        &__brand:nth-child(3n) {
            border-right: 1px solid #F3F8FF;
        }

        &__brand:nth-child(2n) {
            border-right: unset;
        }

        &__brand:nth-child(n+7) {
            border-bottom: 1px solid #F3F8FF;
        }

        &__brand:nth-child(n+9):nth-child(n-12) {
            border-bottom: unset;
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .partners9 {
        &__gallery {
            // display: flex;
            // flex-wrap: wrap;
    
            padding-top: 0px;
            padding-bottom: 40px;
        }

        &__page {
            max-width: 310px;
            // flex: 1 0 100%;
        }

        &__brand:nth-child(2n) {
            border-right: 1px solid #F3F8FF;
        }

        &__brand:nth-child(n) {
            border-right: unset;
        }

        &__brand:nth-child(n+7) {
             border-bottom: unset;
        }

        &__brand {
            height: 150px;
            border: unset;
            border-bottom: unset;
        }

        &__brandwrapper {
            // width: 160px;
            height: 65px;
        }

    }
}