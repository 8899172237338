// 
.partners7 {
    height: 750px;

    display: flex;
    justify-content: center;

    position: relative;

    &__page {
        max-width: 1200px;
        flex: 1 0 100%;
    }

    h2 {
        width: 730px;
        padding-top: 140px;

        font-family: 'OpiumNewC';
        font-style: normal;
        font-weight: 400;
        font-size: 84px;
        line-height: 122%;
        letter-spacing: 0.01em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
        color: #0F2538;
    }

    &__text {
        width: 590px;
        padding-top: 80px;
        padding-bottom: 40px;

        border-bottom: 1px solid;
        border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 100 100;

        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
        letter-spacing: 0.01em;
        color: #38414D;
    }

    &__bg1 {
        position: absolute;
        bottom: -5px;
        right: 0;
    }
}

//1024 * 1919
@media (max-width: 1199px) {
    .partners7 {
        height: 710px;

        &__page {
            max-width: 925px;
            // flex: 1 0 100%;
        }

        h2 {
            width: 530px;
            // padding-top: 140px;
    
            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 55px;
            // line-height: 122%;
            // letter-spacing: 0.01em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #0F2538;
        }

        &__text {
            width: 447px;
            // padding-top: 80px;
            // padding-bottom: 40px;
    
            // border-bottom: 1px solid;
            // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 100 100;
    
            // font-family: 'Noto Sans';
            // font-style: normal;
            // font-weight: 400;
            // font-size: 16px;
            // line-height: 180%;
            // letter-spacing: 0.01em;
            // color: #38414D;
        }

        &__bg1 {
            // position: absolute;
            bottom: 0px;
            // right: 0;
            height: 90%;

            img {
                height: 100%;
            }

        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .partners7 {
        height: 590px;

        &__page {
            max-width: 667px;
            // flex: 1 0 100%;
        }

        h2 {
            width: 530px;
            // padding-top: 140px;
    
            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 47px;
            // line-height: 122%;
            // letter-spacing: 0.01em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #0F2538;
        }

        &__text {
            width: 435px;
            padding-top: 56px;
            padding-bottom: 32px;
    
            // border-bottom: 1px solid;
            // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 100 100;
    
            // font-family: 'Noto Sans';
            // font-style: normal;
            // font-weight: 400;
            font-size: 15px;
            // line-height: 180%;
            // letter-spacing: 0.01em;
            // color: #38414D;
        }

        &__bg1 {
            // position: absolute;
            bottom: 0px;
            // right: 0;
            height: 100%;

            img {
                // height: 100%;
            }

        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .partners7 {
        height: 600px;

        &__page {
            z-index: 100;
            // max-width: 256px;

            width: 100%;
            padding-left: 32px;
            padding-right: 32px;
            // flex: 1 0 100%;
        }

        h2 {
            // width: 256px;
            width: 100%;

            padding-top: 30px;
    
            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 32px;
            // line-height: 122%;
            // letter-spacing: 0.01em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #0F2538;
        }

        &__text {
            // width: 256px;

            width: 100%;

            // padding-top: 56px;
            // padding-bottom: 32px;
    
            // border-bottom: 1px solid;
            // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 100 100;
    
            // font-family: 'Noto Sans';
            // font-style: normal;
            // font-weight: 400;
            font-size: 14px;
            // line-height: 180%;
            // letter-spacing: 0.01em;
            // color: #38414D;
        }

        &__bg1 {
            // position: absolute;
            // bottom: 0px;
            // right: 0;
            height: 70%;

            img {
                // height: 100%;
            }
        }
    }
}