//description / описание
.description {
    display: flex;
    align-items: center;

    height: 12px;

    &__line {
        height: 1px;
        width: 120px;
        background: #A0A8B2;
        opacity: 0.4;
    }

    &__text {
        padding-left: 30px;

        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: #A0A8B3;
    }
}
@media (max-width: 1919px) {
    .description {
        // top: 143px;

        &__line {
            width: 77px;
        }

        &__text {
            padding-left: 30px;
        }
    }
}

@media (max-width: 1699px) {
    .description {
        // top: 143px;

        &__line {
            width: 30px;
        }

        &__text {
            padding-left: 20px;
        }
    }
}

//1024 * 1279
@media (max-width: 1279px) {
    .description {
        // top: 143px;

        &__line {
            width: 20px;
        }

        &__text {
            padding-left: 30px;
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .description {
        // top: 115px;
        // left: 32px;

        &__line {
            width: 25px;
        }

        &__text {
            padding-left: 25px;

            // font-family: 'Noto Sans';
            // font-style: normal;
            // font-weight: 500;
            // font-size: 10px;
            // line-height: 16px;
            // letter-spacing: 0.07em;
            // text-transform: uppercase;
            // color: #A0A8B3;
        }
    }

}

//320 * 767
@media (max-width: 767px) {
    .description {
        // top: 115px;
        // left: 32px;

        &__line {
            display: none;
        }

        &__text {
            padding-left: 32px;

            // font-family: 'Noto Sans';
            // font-style: normal;
            // font-weight: 500;
            font-size: 10px;
            // line-height: 16px;
            // letter-spacing: 0.07em;
            // text-transform: uppercase;
            // color: #A0A8B3;
        }
    }
}