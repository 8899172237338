.documents3 {
    display: flex;
    justify-content: center;

    &__page {
        max-width: 1200px;
        flex: 1 0 100%;

        padding: 142px 0 39px 0;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    &__selectcustom__hidden {
        display: none;

        margin-left: 40px;
        width: 95px;
        position: relative;

        position: relative;
        top: 25px;

        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 130%;
        color: #A0A8B3;

        &_target {
            display: flex;
            justify-content: space-between;
        }

        &_hidden{
            display: inline;
        }
    }

    &__selectcustom {
        margin-left: 40px;
        width: 95px;
        position: relative;
        cursor: pointer;

        position: relative;
        top: 25px;

        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 130%;
        color: #0F2538;

        &_target {
            display: flex;
            justify-content: space-between;
        }

        ul {
            box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
            background-color: white;

            width: 100%;
            position: absolute;
            list-style: none;
            border: solid 1px black;
            cursor: pointer;

            li {
                padding: 5px;

                &:hover {
                    color: rgb(255, 255, 255);
                    background-color: #39a1fc;
                }
            }
        }

        &_active {
            display: block;
            z-index: 1;
        }

        &_passive {
            display: none;
        }

        &_blue {
            color: rgb(255, 255, 255);
            background-color: #39a1fc;
        }

        &_hidden{
            display: none;
        }
    }

    &__selectcustom2 {
        margin-left: 40px;
        width: 161px;
        position: relative;
        cursor: pointer;

        position: relative;
        top: 25px;

        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 130%;
        color: #0F2538;

        &_target {
            display: flex;
            justify-content: space-between;
        }

        ul {
            box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
            background-color: white;

            width: 100%;
            position: absolute;
            list-style: none;
            border: solid 1px black;
            cursor: pointer;

            li {
                padding: 5px;

                &:hover {
                    color: rgb(255, 255, 255);
                    background-color: #39a1fc;
                }
            }
        }

        &_active {
            display: block;
            z-index: 1;
        }

        &_passive {
            display: none;
        }

        &_blue {
            color: rgb(255, 255, 255);
            background-color: #39a1fc;
        }
    }

    &__gallery {
        display: flex;
        flex-direction: column;
    }

    &__menu {
        display: flex;
    }

    &__heading {
        h2 {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 44px;
            line-height: 135%;
            letter-spacing: 0.02em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            color: #0F2538;
        }
    }

    &__content {
        padding-top: 60px;

        h3 {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 160%;
            letter-spacing: 0.03em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            color: #0F2538;
        }

        ul {
            padding-top: 25px;

            list-style: none;



            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #A0A8B3;

            li {
                padding-bottom: 10px;
            }

            a {
                text-decoration: none;
                color: #A0A8B3;

                transition: color 0.3s linear;

                &:hover {
                    color: #0F2538;
                }
            }
        }
    }

    &__agreements {
        li {
            opacity: 1;
            transform: none;
        }
    }

    &__rules {
        padding-top: 95px;
    }

    &__services {
        padding-top: 95px;
    }
}

//1024 * 1919
@media (max-width: 1199px) {
    .documents3 {
        &__page {
            max-width: 926px;
            // flex: 1 0 100%;

            padding: 148px 0;

            // display: flex;
            // flex-direction: column;
            // justify-content: flex-start;
            // align-items: flex-start;
        }

        &__selectcustom__hidden {
            top: 10px;
        }

        &__selectcustom {
            // margin-left: 40px;
            // width: 95px;
            // position: relative;
            // cursor: pointer;
    
            // position: relative;
            top: 10px;
    
            // font-family: 'Noto Sans';
            // font-style: normal;
            // font-weight: 400;
            // font-size: 20px;
            // line-height: 130%;
            // color: #0F2538;
        }

        &__selectcustom2 {
            // margin-left: 40px;
            // width: 161px;
            // position: relative;
            // cursor: pointer;
    
            // position: relative;
            top: 10px;
    
            // font-family: 'Noto Sans';
            // font-style: normal;
            // font-weight: 400;
            // font-size: 20px;
            // line-height: 130%;
            // color: #0F2538;
        }

        &__heading {
            h2 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 29px;
                // line-height: 135%;
                // letter-spacing: 0.02em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__content {
            h3 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 18px;
                // line-height: 160%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }

            ul {
                // padding-top: 25px;

                // list-style: none;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 16px;
                // line-height: 150%;
                // color: #A0A8B3;

                li {
                    // padding-bottom: 10px;
                }
            }
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .documents3 {
        &__page {
            max-width: 668px;
            // flex: 1 0 100%;

            padding: 130px 0 120px 0;

            // display: flex;
            // flex-direction: column;
            // justify-content: flex-start;
            // align-items: flex-start;
        }

        &__selectcustom__hidden {
            width: 90px;
            top: 6px;
            font-size: 18px;
        }

        &__selectcustom {
            // margin-left: 40px;
            width: 90px;
            // position: relative;
            // cursor: pointer;
    
            // position: relative;
            top: 6px;
    
            // font-family: 'Noto Sans';
            // font-style: normal;
            // font-weight: 400;
            font-size: 18px;
            // line-height: 130%;
            // color: #0F2538;
        }

        &__selectcustom2 {
            // margin-left: 40px;
            width: 150px;
            // position: relative;
            // cursor: pointer;
    
            // position: relative;
            top: 6px;
    
            // font-family: 'Noto Sans';
            // font-style: normal;
            // font-weight: 400;
            font-size: 18px;
            // line-height: 130%;
            // color: #0F2538;
        }

        &__gallery {
            // display: flex;
            flex-direction: column;
        }

        &__menu {
            flex: 1 0 auto;
        }

        &__heading {
            h2 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 25px;
                // line-height: 135%;
                // letter-spacing: 0.02em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__content {
            padding-top: 56px;

            h3 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 17px;
                // line-height: 160%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }

            ul {
                // padding-top: 25px;

                // list-style: none;
                list-style-image: url(./images/download.svg);
                position: relative;
                left: 11px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 14px;
                // line-height: 150%;
                // color: #A0A8B3;

                li {
                    // padding-bottom: 10px;
                }
            }
        }

        &__agreements {
            // padding-top: 56px;
        }

        &__rules {
            padding-top: 56px;
        }

        &__services {
            padding-top: 56px;
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .documents3 {
        &__page {
            // max-width: 256px;
            // flex: 1 0 100%;

            padding: 90px 0 48px 0;

            max-width: 100%;
            width: 100%;
            padding-left: 32px;
            padding-right: 32px;

            // display: flex;
            // flex-direction: column;
            // justify-content: flex-start;
            // align-items: flex-start;
        }

        &__selectcustom__hidden {
            margin-left: 0px;
            top: 0px;
            padding-top: 15px;
        }

        &__selectcustom {
            margin-left: 0px;
            // width: 95px;
            // position: relative;
            // cursor: pointer;
    
            // position: relative;
            top: 0px;
            padding-top: 15px;
    
            // font-family: 'Noto Sans';
            // font-style: normal;
            // font-weight: 400;
            // font-size: 20px;
            // line-height: 130%;
            // color: #0F2538;
        }

        &__selectcustom2 {
            margin-left: 0px;
            // width: 161px;
            // position: relative;
            // cursor: pointer;
    
            // position: relative;
            top: 0px;
            padding-top: 15px;
    
            // font-family: 'Noto Sans';
            // font-style: normal;
            // font-weight: 400;
            // font-size: 20px;
            // line-height: 130%;
            // color: #0F2538;
        }

        &__gallery {
            width: 100%;
        }

        &__menu {
            // flex: 1 0 auto;
            flex-direction: column;
        }

        &__heading {
            h2 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 22px;
                // line-height: 135%;
                // letter-spacing: 0.02em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__ul {
            ul {
                // padding-top: 48px;
                // list-style: none;

                // display: flex;

                // width: 256px;
                width: auto;

                // overflow-x: scroll;

                li {
                    // padding-bottom: 0px;
                    // padding-right: 20px;

                    // font-family: 'Noto Sans';
                    // font-style: normal;
                    // font-weight: 400;
                    // font-size: 18px;
                    // line-height: 130%;
                }
            }

            &_active {
                // color: #002157;
            }

            &_passive {
                // color: #A0A8B3;
            }
        }

        &__ulwrapper {
            // width: 300px;
            mask-image: linear-gradient(to right, white 80%, transparent);
        }

        &__content {
            h3 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 16px;
                // line-height: 160%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }

            ul {

                // width: 256px;
                // padding-top: 25px;

                // list-style: none;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 14px;
                // line-height: 150%;
                // color: #A0A8B3;

                li {
                    // padding-bottom: 10px;

                    width: 100%;
                }
            }
        }
    }
}