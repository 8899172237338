//
.team2 {
    background: #F8F8F8;
    height: 456px;

    display: flex;
    justify-content: center;

    &__page {
        max-width: 1200px;
        flex: 1 0 100%;

        display: flex;
        justify-content: center;
    }

    &__content1 {
        flex: 1 0 50%;
    }

    &__heading {
        padding-top: 150px;

        font-family: 'OpiumNewC';
        font-style: normal;
        font-weight: 400;
        font-size: 44px;
        line-height: 135%;
        letter-spacing: 0.02em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
        color: #0F2538;
    }

    &__question {
        padding-top: 55px;

        display: flex;
        align-items: flex-end;

        span {
            flex: 1 0 40%;

            font-family: 'OpiumNewC';
            font-style: italic;
            font-weight: 400;
            font-size: 24px;
            line-height: 122%;
            letter-spacing: 0.03em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #002157;
        }
    }

    &__link {
        flex: 1 0 50%;

        span {
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 130%;
            color: #A0A8B3;
        }
    }

    &__content2 {
        flex: 1 0 50%;
        padding-right: 40px;
    }

    &__text {
        padding-top: 160px;

        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
        letter-spacing: 0.01em;
        color: #38414D;
    }

    &__line {
        padding-top: 40px;
    }
}

//1024 * 1919
@media (max-width: 1199px) {
    .team2 {
        height: 514px;

        &__page {
            max-width: 923px;
            // flex: 1 0 100%;

            // display: flex;
            // justify-content: center;
        }

        &__heading {
            // padding-top: 150px;

            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 29px;
            // line-height: 135%;
            // letter-spacing: 0.02em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #0F2538;
        }

        &__question {
            padding-top: 55px;

            // display: flex;
            flex-direction: column;
            align-items: flex-start;

            span {
                // flex: 1 0 40%;

                // font-family: 'OpiumNewC';
                // font-style: italic;
                // font-weight: 400;
                font-size: 20px;
                // line-height: 122%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;

                width: 270px;
            }
        }

        &__link {
            // flex: 1 0 50%;
            padding-top: 66px;
            align-self: flex-end;
            width: 300px;

            span {
                //     font-family: 'Noto Sans';
                //     font-style: normal;
                //     font-weight: 400;
                //     font-size: 20px;
                //     line-height: 130%;
                //     color: #A0A8B3;
            }
        }

        &__content2 {
            // flex: 1 0 50%;
            padding-right: 0px;
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .team2 {
        height: 686px;

        &__page {
            max-width: 686px;
            // flex: 1 0 100%;

            // display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }

        &__heading {
            padding-top: 140px;

            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 25px;
            // line-height: 135%;
            // letter-spacing: 0.02em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #0F2538;
        }

        &__question {
            padding-top: 48px;

            // display: flex;
            flex-direction: column;
            align-items: flex-start;

            span {
                // flex: 1 0 40%;

                // font-family: 'OpiumNewC';
                // font-style: italic;
                // font-weight: 400;
                font-size: 18px;
                // line-height: 122%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;

                width: 200px;
            }
        }

        &__link {
            // flex: 1 0 50%;
            padding-top: 34px;
            align-self: flex-start;
            width: 300px;
            padding-left: 164px;

            span {
                //     font-family: 'Noto Sans';
                //     font-style: normal;
                //     font-weight: 400;
                //     font-size: 20px;
                //     line-height: 130%;
                //     color: #A0A8B3;
            }
        }

        &__text {
            padding-top: 38px;

            // font-family: 'Noto Sans';
            // font-style: normal;
            // font-weight: 400;
            font-size: 15px;
            // line-height: 180%;
            // letter-spacing: 0.01em;
            // color: #38414D;

            width: 435px;
        }

        &__line {
            // padding-top: 40px;
            width: 435px;
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .team2 {
        height: 642px;

        &__page {
            max-width: 100%;
            width: 100%;
            padding-left: 32px;
            padding-right: 32px;
            // flex: 1 0 100%;

            // display: flex;
            // flex-direction: column;
            // justify-content: flex-start;
        }

        &__heading {
            padding-top: 100px;

            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 22px;
            // line-height: 135%;
            // letter-spacing: 0.02em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #0F2538;
        }

        &__question {
            padding-top: 32px;

            // display: flex;
            // flex-direction: column;
            // align-items: flex-start;

            span {
                // flex: 1 0 40%;

                // font-family: 'OpiumNewC';
                // font-style: italic;
                // font-weight: 400;
                font-size: 16px;
                // line-height: 122%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;

                // width: 200px;
            }
        }

        &__link {
            // flex: 1 0 50%;
            // padding-top: 34px;
            // align-self: flex-start;
            width: 256px;
            width: 100%;


            padding-left: 100px;

            span {
                //     font-family: 'Noto Sans';
                //     font-style: normal;
                //     font-weight: 400;
                //     font-size: 20px;
                //     line-height: 130%;
                //     color: #A0A8B3;
            }
        }

        &__text {
            padding-top: 0px;

            // font-family: 'Noto Sans';
            // font-style: normal;
            // font-weight: 400;
            font-size: 14px;
            // line-height: 180%;
            // letter-spacing: 0.01em;
            // color: #38414D;

            // width: 256px;
            width: 100%;
        }

        &__line {
            padding-top: 30px;
            // width: 256px;
            width: 100%;
        }
    }
}