.company4 {
    // height: 956px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__page {
        max-width: 1128px;
        flex: 1 0 100%;

        padding: 160px 0 160px;
    }

    &__heading {
        h2 {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 84px;
            line-height: 122%;
            letter-spacing: 0.01em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            color: #0F2538;
        }
    }

    &__content {
        padding-top: 80px;

        display: flex;
    }

    &__text {
        flex: 1 0 73%;

        padding-right: 100px;
        height: 470px;
        width: 688px;

        overflow: hidden;
    }

    &__textheading {
        h3 {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 160%;
            letter-spacing: 0.03em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            color: #0F2538;
        }
    }

    &__textbody {
        padding-top: 40px;

        p {
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 180%;
            letter-spacing: 0.01em;
            color: #38414D;
        }
    }

    &__years {
        flex: 1 0 27%;

        border-left: solid 1px black;
        border-image: linear-gradient(to bottom, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 1;
        padding-left: 100px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &_span {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            font-size: 1.1rem;
            height: 1.4em;

            transition: all 0.3s ease-out 0s;

            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            // font-size: 23px;
            line-height: 122%;
            text-align: right;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            color: #0F2538;
            opacity: 0.5;

            &:hover {
                cursor: pointer;
            }
        }

        &_active0 {
            font-size: 3rem;
            opacity: 1;
        }

        &_active1 {
            font-size: 2.25rem;
            opacity: 0.7;
        }

        &_active2 {
            font-size: 1.6rem;
            opacity: 0.65;
        }

        &_active3 {
            font-size: 1.4rem;
            opacity: 0.6;
        }

        &_active4 {
            font-size: 1.2rem;
            opacity: 0.5;
        }

        &_active5 {
            font-size: 1.1rem;
            opacity: 0.4;
        }

        &_active6 {
            font-size: 1.1rem;
            opacity: 0.35;
        }

        &_active7 {
            font-size: 1.1rem;
            opacity: 0.30;
        }

        &_active {
            opacity: 1;
            transform: scale(1.3);

            @media (max-width: 767px) {
                transform: scale(1.1);
            }
        }

        span {
            height: 29px;
            display: flex;
            align-items: center;
            transform-origin: 0 50%;

            @media (max-width: 767px) {
                transform-origin: 50% 50%;
            }

            &:hover {
                opacity: 1;
                transform: scale(1.3);

                @media (max-width: 767px) {
                    transform: scale(1.1);
                }
            }
        }
    }
}

//1700 * 1919
@media (max-width: 1919px) {
    .company4 {
        &__heading {
            h2 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 77px;
                // line-height: 122%;
                // letter-spacing: 0.01em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__textheading {
            h3 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 21px;
                // line-height: 160%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }
    }
}

//1280 * 1699
@media (max-width: 1699px) {
    .company4 {
        &__page {
            max-width: 940px;
            // flex: 1 0 100%;

            // padding: 160px 0 160px;
        }

        &__text {
            // flex: 1 0 73%;

            padding-right: 80px;
            // height: 470px;
            // width: 688px;

            // overflow: hidden;
        }

        &__heading {
            h2 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 63px;
                // line-height: 122%;
                // letter-spacing: 0.01em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__textheading {
            h3 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 19px;
                // line-height: 160%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__years {
            // flex: 1 0 27%;

            // border-left: solid 1px black;
            // border-image: linear-gradient(to bottom, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 1;
            padding-left: 80px;

            // display: flex;
            // flex-direction: column;
            // align-items: flex-start;
        }
    }
}

//1024 * 1279
@media (max-width: 1279px) {
    .company4 {
        &__heading {
            h2 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 55px;
                // line-height: 122%;
                // letter-spacing: 0.01em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }



        &__textheading {
            h3 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 18px;
                // line-height: 160%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }


    }
}

//768 * 1023
@media(max-width: 1023px) {
    .company4 {
        &__page {
            max-width: 668px;
            // flex: 1 0 100%;

            padding: 140px 0 140px;
        }

        &__heading {
            h2 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 47px;
                // line-height: 122%;
                // letter-spacing: 0.01em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__content {
            padding-top: 56px;

            // display: flex;
        }

        &__text {
            flex: 1 0 65%;

            padding-right: 50px;
            // height: 470px;
            // width: 688px;

            // overflow: hidden;
        }

        &__textheading {
            h3 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 17px;
                // line-height: 160%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__years {
            flex: 1 0 35%;

            // border-left: solid 1px black;
            // border-image: linear-gradient(to bottom, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 1;
            padding-left: 50px;

            // display: flex;
            // flex-direction: column;
            // align-items: flex-start;
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .company4 {
        &__page {
            // max-width: 256px;
            width: 100%;

            // flex: 1 0 100%;

            padding: 100px 0 100px;

            padding-left: 32px;
            padding-right: 32px;
        }

        &__heading {
            h2 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 32px;
                // line-height: 122%;
                // letter-spacing: 0.01em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__content {
            padding-top: 56px;

            // display: flex;
            flex-direction: column-reverse;
        }

        &__text {
            // flex: 1 0 65%;

            padding-right: 0px;
            height: 800px;
            width: 100%;

            // overflow: hidden;
        }

        &__textheading {
            padding-top: 56px;

            h3 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 17px;
                // line-height: 160%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__textbody {
            padding-top: 30px;

            p {
                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 16px;
                // line-height: 180%;
                // letter-spacing: 0.01em;
                // color: #38414D;
            }
        }

        &__years {
            overflow-y: hidden;
            overflow-x: scroll;
            border-left: none;
            border-bottom: solid 1px black;
            border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 1;
            padding-left: 0;
            padding-bottom: 20px;
            flex-direction: row;
            margin: 0 -8px;
            mask-image: linear-gradient(to right, white 80%, transparent);

            span {
                padding-left: 8px;
                padding-right: 8px;
                font-family: 'OpiumNewC';
                font-style: normal;
                font-weight: 400;
                font-size: 22px;
                line-height: 122%;
                text-align: right;
                font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                color: #0F2538;
            }
        }
    }
}
