// 
.vacancy4 {
    display: flex;
    justify-content: center;

    &__page {
        max-width: 1200px;
        flex: 1 0 100%;

        display: flex;
        flex-direction: column;

        padding: 140px 0;
    }

    &__head {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__heading {
        padding-right: 150px;
        h2 {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 84px;
            line-height: 122%;
            letter-spacing: 0.01em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            color: #0F2538;
        }
    }

    &__contacts {
        width: 650px;
    }

    &__welcome {
        padding-top: 30px;
        width: 400px;

        P {
            font-family: 'OpiumNewC';
            font-style: italic;
            font-weight: 400;
            font-size: 24px;
            line-height: 145%;
            letter-spacing: 0.01em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #0F2538;
        }
    }

    &__data {
        padding-top: 40px;
    }

    &__mail {
        span {
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 180%;
            letter-spacing: 0.01em;
            color: #38414D;
        }

        a {
            text-decoration: none;
            // color: black;

            &:hover{
                color: #403EC0;
                // color: #A5ADB8;
            }
        }
    }

    &__phones {
        padding-top: 5px;

        span {
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 180%;
            letter-spacing: 0.01em;
            color: #38414D;
        }

        a {
            text-decoration: none;
            // color: #38414D;
            &:hover{
                color:  #403EC0;
                // color: #A5ADB8;
            }
        }

        &_inline {
            display: inline;
        }
    }

    &__vacancies {
        padding-top: 100px;

        ul {
            list-style: none;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 130%;
            color: #0F2538;

            a{
                text-decoration: none;
            }

            li {
                height: 85px;

                display: flex;
                align-items: center;
                justify-content: space-between;

                border-top: solid 1px rgba(165, 173, 184, 0.4);
                border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05))1;

                &:nth-last-child(1) {
                    border-bottom: solid 1px rgba(165, 173, 184, 0.4);
                }
            }

            span {
                font-family: 'Noto Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 130%;
                color: #38414D;
            }
        }

        img {
            width: 50px;
            margin-left: 20px;

            // display: block;

            padding-bottom: 3px;
        }
    }
}

//1024 * 1919
@media (max-width: 1199px) {
    .vacancy4 {

        &__page {
            max-width: 924px;
            // flex: 1 0 100%;

            // display: flex;
            // flex-direction: column;

            // padding: 140px 0;
        }

        &__heading {
            h2 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 55px;
                // line-height: 122%;
                // letter-spacing: 0.01em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__contacts {
            width: 390px;
        }

        &__welcome {
            padding-top: 17px;
            width: auto;

            P {
                //     font-family: 'OpiumNewC';
                //     font-style: italic;
                //     font-weight: 400;
                font-size: 20px;
                //     line-height: 145%;
                //     letter-spacing: 0.01em;
                //     font-feature-settings: 'pnum' on, 'lnum' on;
                //     color: #0F2538;
            }
        }

        &__vacancies {
            padding-top: 100px;

            ul {
                // list-style: none;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 20px;
                // line-height: 130%;
                // color: #0F2538;

                li {
                    // height: 85px;

                    // display: flex;
                    // align-items: center;
                    // justify-content: space-between;

                    // border-top: solid 1px rgba(165, 173, 184, 0.4);
                    // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05))1;

                    &:nth-child(5) {
                        // border-bottom: solid 1px rgba(165, 173, 184, 0.4);
                    }
                }

                span {
                    // font-family: 'Noto Sans';
                    // font-style: normal;
                    // font-weight: 400;
                    // font-size: 20px;
                    // line-height: 130%;
                    // color: #38414D;
                }
            }

            img {
                // width: 59px;
                // margin-left: 20px;
            }
        }

    }
}

//768 * 1023
@media(max-width: 1023px) {
    .vacancy4 {
        &__page {
            max-width: 668px;
            // flex: 1 0 100%;

            // display: flex;
            // flex-direction: column;

            padding: 120px 0;
        }

        &__heading {
            padding-right: 30px;
            h2 {

                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 47px;
                // line-height: 122%;
                // letter-spacing: 0.01em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__contacts {
            width: auto;
        }

        &__welcome {
            padding-top: 15px;
            // width: auto;

            P {
                //     font-family: 'OpiumNewC';
                //     font-style: italic;
                //     font-weight: 400;
                font-size: 18px;
                //     line-height: 145%;
                //     letter-spacing: 0.01em;
                //     font-feature-settings: 'pnum' on, 'lnum' on;
                //     color: #0F2538;
            }
        }

        &__mail {
            span {
                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 15px;
                // line-height: 180%;
                // letter-spacing: 0.01em;
                // color: #38414D;
            }

            a {
                // text-decoration: none;
            }
        }

        &__phones {
            padding-top: 10px;

            span {

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 15px;
                // line-height: 180%;
                // letter-spacing: 0.01em;
                // color: #38414D;
            }

            a {
                // text-decoration: none;
                padding-bottom: 15px;
            }

            &_inline {
                display: flex;
                flex-direction: column;

                width: 150px;

                position: relative;
                top: -24px;
                left: 85px;
            }
        }

        &__vacancies {
            padding-top: 33px;

            ul {
                // list-style: none;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 18px;
                // line-height: 130%;
                // color: #0F2538;

                li {
                    height: auto;

                    // display: flex;
                    // align-items: center;
                    // justify-content: space-between;

                    // border-top: solid 1px rgba(165, 173, 184, 0.4);
                    // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05))1;

                    &:nth-child(5) {
                        // border-bottom: solid 1px rgba(165, 173, 184, 0.4);
                    }
                }

                span {
                    // font-family: 'Noto Sans';
                    // font-style: normal;
                    // font-weight: 400;
                    font-size: 18px;
                    // line-height: 130%;
                    // color: #38414D;
                }
            }

            img {
                width: 50px;
                margin-left: 20px;
            }

            &_first {
                width: 350px;
                padding: 30px 0;
            }
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .vacancy4 {
        &__page {
            // max-width: 256px;
            max-width: 100%;
            // flex: 1 0 100%;

            // display: flex;
            // flex-direction: column;

            padding: 80px 0;

            max-width: 100%;
            padding-left: 32px;
            padding-right: 32px;
        }

        &__head {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
        }

        &__heading {
            h2 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 32px;
                // line-height: 122%;
                // letter-spacing: 0.01em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__welcome {
            padding-top: 48px;
            // width: auto;

            P {
                //     font-family: 'OpiumNewC';
                //     font-style: italic;
                //     font-weight: 400;
                font-size: 16px;
                //     line-height: 145%;
                //     letter-spacing: 0.01em;
                //     font-feature-settings: 'pnum' on, 'lnum' on;
                //     color: #0F2538;
            }
        }

        &__data {
            padding-top: 30px;
        }

        &__mail {
            span {
                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 14px;
                // line-height: 180%;
                // letter-spacing: 0.01em;
                // color: #38414D;
            }

            a {
                // text-decoration: none;

                font-size: 14px;
            }
        }

        &__phones {
            padding-top: 10px;

            span {

                // display: block;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 14px;
                // line-height: 180%;
                // letter-spacing: 0.01em;
                // color: #38414D;
            }

            a {
                // text-decoration: none;
                padding-bottom: 15px;

                font-size: 14px;

                
            }

            &_inline {
                display: flex;
                // flex-direction: column;
                // flex-direction: row;
                // flex-wrap: wrap;

                // width: 150px;
                // width: auto;

                // max-width: 100%;

                position: relative;
                top: -21px;
                // left: 85px;

                a{
                    // display: block;
                    padding-left: 10px;
                    width: auto;
                }
            }
        }

        &__vacancies {
            // padding-top: 33px;

            ul {
                // list-style: none;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 16px;
                // line-height: 130%;
                // color: #0F2538;

                li {
                    // height: auto;

                    // display: flex;
                    // align-items: center;
                    // justify-content: space-between;

                    // border-top: solid 1px rgba(165, 173, 184, 0.4);
                    // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05))1;

                    &:nth-child(5) {
                        // border-bottom: solid 1px rgba(165, 173, 184, 0.4);
                    }
                }

                span {
                    // font-family: 'Noto Sans';
                    // font-style: normal;
                    // font-weight: 400;
                    font-size: 16px;
                    // line-height: 130%;
                    // color: #38414D;
                }
            }

            img {
                // width: 50px;
                // margin-left: 20px;
            }

            &_first {
                // width: 350px;
                // padding: 30px 0;
            }

            &_second {
                display: none;
            }
        }
    }
}