.galleryslider2 {
    z-index: 1000;
    cursor: auto;
    position: fixed;

    background-color: rgba(0, 0, 0, 0.8);
    height: 100%;
    width: 100%;

    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    &__imgwrapper {
        flex: 1 0 100%;
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;
        
        img {
            // display: block;
            height: 100%;
            width: auto;

            // height: auto;
            // width: 100%;

            padding: 32px;
        }
    }

    &__closebutton {
        position: absolute;
        // background-color: unset;
        background-color: rgb(0, 0, 0, 0.1);

        height: 30px;
        width: 30px;

        cursor: pointer;
        top: 15px;
        right: 15px;

        img {
            height: 100%;
            width: auto;
        }
    }

    &__rightbutton,
    &__leftbutton {
        cursor: pointer;
        position: absolute;
        // background-color: unset;
        background-color: rgb(0, 0, 0, 0.1);

        height: 40px;
        width: 40px;

        img {
            height: 100%;
            width: auto;
        }
    }

    &__rightbutton {
        right: 0;
    }

    &__leftbutton {
        left: 0;
        transform: rotate(180deg);
    }
}