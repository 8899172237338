// 
.partners4 {
    z-index: 1;
    height: 703px;

    display: flex;
    justify-content: center;
    align-items: center;

    &__page {
        max-width: 1200px;
        flex: 1 0 100%;

        display: flex;
        justify-content: space-between;
    }

    &__ul1 {
        z-index: 1;

        h3 {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 44px;
            line-height: 135%;
            letter-spacing: 0.02em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            color: #0F2538;
        }

        span {
            display: block;
            padding-top: 60px;


            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 160%;
            letter-spacing: 0.03em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            color: #0F2538;
        }

        ul {
            padding-top: 40px;
            padding-left: 15px;

            // 
            // list-style: url(../../../../images/Polygon.svg);

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #38414D;
        }

        li {
            padding-bottom: 10px;
            list-style: none;

            &::before {
                content: '';
                display: block;
                height: 6px;
                width: 6px;
                background: url(../../../../images/Polygon.svg) no-repeat center;

                position: relative;
                top: 15px;
                left: -15px;
            }
        }
    }

    &__line {
        height: 480px;
        width: 1px;
        background-color: #002157;
        background: linear-gradient(to bottom, rgba(140, 154, 176, 0), rgba(140, 154, 176, 0.7), rgba(140, 154, 176, 0));
    }
}


//1024 * 1919
@media (max-width: 1199px) {
    .partners4 {
        &__page {
            max-width: 925px;
            // flex: 1 0 100%;

            // display: flex;
            // justify-content: space-between;
        }

        &__ul1 {
            width: 400px;

            h3 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 29px;
                // line-height: 135%;
                // letter-spacing: 0.02em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }

            span {
                // display: block;
                // padding-top: 60px;


                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 18px;
                // line-height: 160%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }

        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .partners4 {
        height: 850px;

        &__page {
            max-width: 450px;
            // flex: 1 0 100%;

            // display: flex;
            justify-content: flex-start;
            flex-direction: column;
        }

        &__ul1 {
            width: 450px;

            h3 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 25px;
                // line-height: 135%;
                // letter-spacing: 0.02em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }

            span {
                // display: block;
                padding-top: 40px;


                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 17px;
                // line-height: 160%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }

            ul {
                padding-top: 20px;
                padding-bottom: 60px;
                // list-style: unset;
                // padding-left: 20px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 14px;
                // line-height: 150%;
                // color: #38414D;
            }

            li {
                padding-bottom: 0px;

                &::before {
                    content: '';
                    // font-size: 40px;
                    // position: relative;
                    // top: -3px;
                    // padding-right: 0px;
                }
            }
        }

        &__line {
            display: none;
            // height: 480px;
            // width: 1px;
            // background-color: #002157;
            // background: linear-gradient(to bottom, rgba(140, 154, 176, 0), rgba(140, 154, 176, 1), rgba(140, 154, 176, 0));
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .partners4 {
        height: 900px;

        justify-content: flex-start;

        &__page {
            // max-width: 256px;
            max-width: 100%;
            width: 100%;
            padding-left: 32px;
            padding-right: 32px;

            // flex: 1 0 100%;

            // display: flex;
            // justify-content: flex-start;
            // flex-direction: column;
        }

        &__ul1 {
            // width: 256px;
            width: 100%;


            h3 {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 22px;
                // line-height: 135%;
                // letter-spacing: 0.02em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }

            span {
                // display: block;
                padding-top: 30px;


                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 16px;
                // line-height: 160%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }

            ul {
                padding-top: 10px;
                // padding-bottom: 60px;
                // list-style: unset;
                // padding-left: 20px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                // color: #38414D;
            }

            li {
                // padding-bottom: 0px;

                padding: 4px 0;

                &::before {
                    content: '';
                    // font-size: 40px;
                    // position: relative;
                    // top: -3px;
                    // padding-right: 0px;
                }
            }
        }
    }
}