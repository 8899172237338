.header {
    //background-color: aquamarine;
    z-index: 210;
    width: 100%;

    position: fixed;
    top: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__logo-wrap {
        display: flex;
        align-items: center;
        justify-content: left;
        position: relative;
        left: 150px;
        height: 56px;
        width: 180px;
    }

    &__logo {
        position: absolute;
        height: 100%;
        width: auto;
        transition: all .25s;
        opacity: 0;
        visibility: hidden;

        &--visible {
            transition: all .25s .25s;
            opacity: 1;
            visibility: visible;
        }
    }

    &__links {
        display: flex;
        flex-wrap: wrap;
        transition: all .2s;
        opacity: 0;
        visibility: hidden;

        &--visible {
            transition: all .5s;
            opacity: 1;
            visibility: visible;
        }

        a {
            padding: 10px 20px;
            text-decoration: none;
            color: #A0A8B3;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.07em;
            text-transform: uppercase;

            transition: color 0.3s linear;

            &:hover {
                color: white;
            }
        }
    }

    &__linkslogo {
        width: 180px;
        height: 56px;

        position: relative;
        left: 150px;
        top: 70px;


        display: flex;
        align-items: center;
        justify-content: left;

        img {
            position: absolute;
            height: 100%;
            width: auto;
        }
    }

    &__linksopen {
        background-color: aqua;
        background:
            linear-gradient(0deg,
                rgba(22, 22, 22, 0.3),
                rgba(22, 22, 22, 0.3)),
            linear-gradient(238.17deg,
                rgba(12, 18, 26, 0.6) 37.87%,
                rgba(7, 21, 43, 0.7) 59.92%,
                rgba(6, 23, 51, 0.9) 80.92%,
                #021533 91.44%),
            black;
        background-blend-mode: color, normal;

        position: fixed;

        top: -200vh;
        //top: 0;
        width: 100%;
        height: 100%;

        transition: top 0.3s linear;

        &.active {
            top: 0;
        }
    }

    &__linkswrapper {
        position: absolute;
        top: 200px;
        left: 509px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        a {
            text-decoration: none;

            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 58px;
            line-height: 150%;
            font-feature-settings: 'pnum' on, 'lnum' on;

            color: #A0A8B3;

            transition: color 0.3s linear;

            &:hover {
                color: white;
            }
        }
    }

    &__burger {
        opacity: 1;

        cursor: pointer;
        position: relative;
        right: 45px;

        height: 40px;
        width: 40px;

        display: flex;
        justify-content: center;
        flex-direction: column;

        span {
            position: absolute;
            display: block;
            width: 40px;
            height: 2px;
            background-color: #A0A8B3;

            transition: top 0.2s ease 0.1s, bottom 0.2s ease 0.1s, transform 0.1s ease 0s;

            &:nth-child(1) {
                top: 0px;
                margin-top: 13px;
            }

            &:nth-child(3) {
                bottom: 0px;
                margin-bottom: 13px;
            }
        }

        //нажатие в крест
        &.active {
            span {
                transition: top 0.2s ease 0s, bottom 0.2s ease 0s, transform 0.1s ease 0.2s;

                &:nth-child(1) {
                    top: 6px;
                    transform-origin: 50% 50%;
                    transform: rotate(45deg);
                }

                &:nth-child(2) {
                    opacity: 0;
                }

                &:nth-child(3) {
                    bottom: 6px;
                    transform-origin: 50% 50%;
                    transform: rotate(-45deg);
                }
            }
        }
    }
}

//1024 * 1919
// @media (max-width: 1919px) {
@media (max-width: 1199px) {
    .header {
        //background-color: rgb(255, 196, 124);
        top: 46px;

        &__logo-wrap {
            left: 50px;
            height: 39px;
            width: 120px;
        }

        &__links {
            a {
                padding: 0 15px;
            }
        }

        &__linkslogo {
            width: 120px;
            height: 39px;

            // position: relative;
            left: 50px;
            top: 46px;


            // display: flex;
            // align-items: center;
            // justify-content: left;

            img {
                // position: absolute;
                // height: 100%;
                // width: auto;
            }
        }

        &__openlinks {
            background:
                linear-gradient(238.17deg,
                    rgba(12, 18, 26, 0.6) 37.87%,
                    rgba(7, 21, 43, 0.7) 59.92%,
                    rgba(6, 23, 51, 0.9) 80.92%,
                    #021533 91.44%);
        }

        &__linkswrapper {
            top: 127px;
            left: 245px;

            a {
                //     text-decoration: none;

                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 38px;
                line-height: 160%;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #A0A8B3;

                // transition: color 0.3s linear;

                &:hover {
                    // color: white;
                }
            }
        }

        &__burger {
            opacity: 1;
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .header {
        //background-color: rgb(93, 255, 53);

        &__links {
            display: none;
        }

        &__linkswrapper {
            top: 256px;
            left: 166px;

            a {
                font-size: 33px;
            }
        }

        &__burger {
            right: 50px;
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .header {
        //background-color: rgb(252, 139, 1);
        top: 36px;

        &__logo-wrap {
            left: 32px;
        }

        &__linkswrapper {
            top: 162px;
            left: 32px;
        }

        &__burger {
            right: 31px;
        }
    }
}
