// 
.privacy1 {
    display: flex;
    justify-content: center;

    &__page {
        max-width: 1200px;
        flex: 1 0 100%;

        padding: 100px 20px;
    }

    h1 {
        padding: 20px 0;
        text-align: center;
    }

    p {
        padding: 10px 0;
    }

    ul{
        padding: 20px;

        li{
            padding: 5px;
        }
    }
}