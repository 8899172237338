// 
.documents2 {

    display: flex;
    justify-content: center;

    background: #F8F8F8;

    &__page {
        max-width: 1200px;
        flex: 1 0 100%;

        padding: 160px 0;
    }

    &__text {
        width: 590px;

        P {
            border-bottom: solid 1px black;
            border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 1;
            padding-bottom: 40px;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 180%;
            letter-spacing: 0.01em;
            color: #38414D;
        }
    }
}

//1024 * 1919
@media (max-width: 1199px) {
    .documents2 {

        &__page {
            max-width: 924px;
            // flex: 1 0 100%;
    
            // padding: 160px 0;
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .documents2 {
        &__page {
            max-width: 668px;
            // flex: 1 0 100%;
    
            padding: 140px 0;
        }

        &__text {
            width: 550px;
    
            P {
                // border-bottom: solid 1px black;
                // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 1;
                // padding-bottom: 40px;
    
                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 15px;
                // line-height: 180%;
                // letter-spacing: 0.01em;
            }
        }

    }
}

//320 * 767
@media (max-width: 767px) {
    .documents2 {
        &__page {
            // max-width: 256px;
            // flex: 1 0 100%;
    
            padding: 100px 0;

            max-width: 100%;
            padding-left: 32px;
            padding-right: 32px;
        }

        &__text {
            width: 100%;
    
            P {
                // border-bottom: solid 1px black;
                // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 1;
                padding-bottom: 32px;
    
                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 15px;
                // line-height: 180%;
                // letter-spacing: 0.01em;
            }
        }
    }
}