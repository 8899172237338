// 
.company5 {
    height: 810px;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    span {
        display: flex;
    }

    &__page {
        max-width: 1200px;
        flex: 1 0 100%;

        padding-left: 65px;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__block {
        display: flex;
        flex-direction: column;

        &_big {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 214.8px;
            line-height: 100%;
            letter-spacing: 0.03em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #002157;
        }

        &_bigtext {
width: 300px;
            // padding-left: 10px;
            padding-top: 30px;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 44px;
            line-height: 100%;
            letter-spacing: 0.01em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #0F2538;
        }

        &_middle {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 122px;
            line-height: 100%;
            letter-spacing: 0.03em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #002157;
        }

        &_little {
            width: 20px;
            margin-top: 10px;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 130%;
            color: #0F2538;
        }

        &_plus {
            padding-top: 10px;

            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 700;
            font-size: 65.0139px;
            line-height: 80%;
            letter-spacing: 0.03em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #002157;
        }
    }

    &__text {
        width: 277px;
        border-top: solid 1px;
        border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 1;
        padding-top: 25px;
        margin-top: 10px;

        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
        letter-spacing: 0.01em;
        color: #38414D;
    }

    &__number1,
    &__number2,
    &__number3 {
        z-index: 100;
        width: 25%;

        height: 550px;
    }

    &__number4 {
        display: none;
    }

    &__number1,
    &__number3 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__number2 {
        width: 45%;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }

    &__BG1 {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0px;

        background: url(../../../../images/company5BG1.jpg) left no-repeat;
        background-size: contain;
    }
}

//1700 * 1919
@media (max-width: 1919px) {
    .company5 {

        &__page {
            // max-width: 1200px;
            // flex: 1 0 100%;
    
            padding-left: 70px;
    
            // display: flex;
            // justify-content: center;
            // align-items: center;
        }

        &__block {
            // display: flex;
            // flex-direction: column;

            &_big {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 197px;
                // line-height: 100%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;
            }

            &_bigtext {
                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 40px;
                // line-height: 100%;
                // letter-spacing: 0.01em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #0F2538;
            }

            &_middle {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 111px;
                // line-height: 100%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;
            }

            &_little {
                // width: 20px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 20px;
                // line-height: 130%;
                // color: #0F2538;
            }

            &_plus {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 700;
                font-size: 59px;
                // line-height: 80%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;
            }
        }

        &__number1,
        &__number2,
        &__number3 {
            // z-index: 100;
            // width: 25%;

            height: 490px;
        }

        &__BG1 {
            // height: 100%;
            // width: 100%;
            // position: absolute;
            // top: 0;
            // left: 0px;
    
            background: url(../../../../images/company5BG2.jpg) left no-repeat;
            background-size: contain;
        }
    }
}

//1280 * 1699
@media (max-width: 1699px) {
    .company5 {
        height: 750px;

        &__page {
            max-width: 940px;
            // flex: 1 0 100%;

            // display: flex;
            // justify-content: center;
            // align-items: center;
        }

        &__block {
            // display: flex;
            // flex-direction: column;

            &_big {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 163px;
                // line-height: 100%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;
            }

            &_bigtext {
                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 33px;
                // line-height: 100%;
                // letter-spacing: 0.01em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #0F2538;
            }

            &_middle {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 85px;
                // line-height: 100%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;
            }

            &_little {
                // width: 20px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 16px;
                // line-height: 130%;
                // color: #0F2538;
            }

            &_plus {
                padding-top: 5px;

                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 700;
                font-size: 49px;
                // line-height: 80%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;
            }
        }

        &__number1,
        &__number2,
        &__number3 {
            // z-index: 100;
            // width: 25%;

            height: 420px;
        }

        &__BG1 {
            // height: 100%;
            // width: 100%;
            // position: absolute;
            // top: 0;
            left: -80px;
    
            background: url(../../../../images/company5BG2.jpg) left no-repeat;
            background-size: contain;
        }
    }
}

//1024 * 1279
@media (max-width: 1279px) {
    .company5 {
        height: 720px;

        &__page {
            // max-width: 1200px;
            // flex: 1 0 100%;
    
            padding-left: 55px;
    
            // display: flex;
            // justify-content: center;
            // align-items: center;
        }

        &__block {
            // display: flex;
            // flex-direction: column;

            &_big {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 141px;
                // line-height: 100%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;
            }

            &_bigtext {
                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 29px;
                // line-height: 100%;
                // letter-spacing: 0.01em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #0F2538;
            }

            &_middle {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 73px;
                // line-height: 100%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;
            }

            &_little {
                // width: 20px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 16px;
                // line-height: 130%;
                // color: #0F2538;
            }

            &_plus {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 700;
                font-size: 42px;
                // line-height: 80%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;
            }
        }

        &__number1,
        &__number2,
        &__number3 {
            // z-index: 100;
            // width: 25%;

            height: 450px;
        }

        &__BG1 {
            // height: 100%;
            // width: 100%;
            // position: absolute;
            // top: 0;
            left: 0px;
    
            background: url(../../../../images/company5BG3.jpg) left no-repeat;
            background-size: contain;
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .company5 {
        height: 700px;

        &__page {
            max-width: 668px;
            // flex: 1 0 100%;

            padding-left: 35px;

            // display: flex;
            // justify-content: center;
            // align-items: center;
        }

        &__block {
            // display: flex;
            // flex-direction: column;

            &_big {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 121px;
                // line-height: 100%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;
            }

            &_bigtext {
                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 24px;
                // line-height: 100%;
                // letter-spacing: 0.01em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #0F2538;
            }

            &_middle {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 70px;
                // line-height: 100%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;
            }

            &_little {
                // width: 20px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 15px;
                // line-height: 130%;
                // color: #0F2538;
            }

            &_plus {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 700;
                font-size: 36px;
                // line-height: 80%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;
            }
        }

        &__text {
            width: 240px;
            // border-top: solid 1px;
            // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 1;
            // padding-top: 25px;

            // font-family: 'Noto Sans';
            // font-style: normal;
            // font-weight: 400;
            font-size: 15px;
            line-height: 160%;
            // letter-spacing: 0.01em;
            // color: #38414D;
        }

        &__number1,
        &__number2,
        &__number3 {
            // z-index: 100;
            width: 40%;

            height: 450px;
        }

        &__number2,
        &__number3 {
            display: none;
        }

        &__number4 {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            height: 450px;
            width: 40%;

            padding-left: 80px;
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .company5 {
        height: 770px;

        overflow: hidden;

        &__page {
            // max-width: 256px;
            // flex: 1 0 100%;

            padding-left: 0px;

            width: 100%;
            padding-left: 32px;
            padding-right: 32px;

            // display: flex;
            flex-direction: column;
            // justify-content: center;
            align-items: flex-start;
        }

        &__block {
            // display: flex;
            // flex-direction: column;

            &_big {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 88px;
                // line-height: 100%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;
            }

            &_bigtext {
                width: 200px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 18px;
                // line-height: 100%;
                // letter-spacing: 0.01em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #0F2538;
            }

            &_middle {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 50px;
                // line-height: 100%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;
            }

            &_little {
                // width: 20px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 14px;
                // line-height: 130%;
                // color: #0F2538;
            }

            &_plus {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 700;
                font-size: 27px;
                // line-height: 80%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on;
                // color: #002157;
            }

            &_1,
            &_2,
            &_3 {
                width: 45%;
            }

            &_1{
                order: 2;
            }

            &_2{
                order: 1;
            }
        }

        &__text {
            // width: 240px;
            width: 100%;
            // border-top: solid 1px;
            // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 1;
            padding-top: 15px;

            // font-family: 'Noto Sans';
            // font-style: normal;
            // font-weight: 400;
            font-size: 15px;
            line-height: 160%;
            // letter-spacing: 0.01em;
            // color: #38414D;
        }

        &__BG1 {
            // height: 100%;
            // width: 100%;
            // position: absolute;
            top: -90px;
            left: -75px;
    
            background: url(../../../../images/company5BG3.jpg) left no-repeat;
            background-size: contain;
        }

        &__number1 {
            // z-index: 100;
            width: 100%;

            height: 320px;
        }

        &__number4 {
            z-index: 100;
            // display: flex;
            flex-direction: row;
            // justify-content: space-between;
            flex-wrap: wrap;

            height: 300px;
            width: 100%;

            padding-left: 0px;
            padding-top: 70px;
        }
    }
}