// 
.firstscreen {
    height: 100vh;
    width: 100vw;

    position: relative;

    display: flex;
    justify-content: center;

    &__page {
        max-width: 1200px;
        flex: 1 0 100%;

        display: flex;
        align-items: center;
    }

    &__header {
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__description {
        position: absolute;
        top: 228px;
        left: 0px;
    }

    &__line {
        position: absolute;
        left: 0;
        bottom: 80px;
        width: 60%;
    }
}

//1024 * 1919
@media (max-width: 1199px) {
    .firstscreen {
        &__page {
             max-width: 924px;
            // flex: 1 0 100%;
    
            // display: flex;
            // align-items: center;
        }
        &__description {
            // position: absolute;
            top: 148px;
            // left: 0px;
        }
        &__line {
            // position: absolute;
            // left: 0;
            // bottom: 80px;
            // width: 1133px;
            width: 70%;
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .firstscreen {
        &__page {
             max-width: 668px;
            // flex: 1 0 100%;
    
            // display: flex;
            // align-items: center;
        }
        &__description {
            // position: absolute;
             top: 148px;
            // left: 0px;
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .firstscreen {
        &__page {
            // max-width: 256px;
            width: auto;
            padding-left: 32px;
            padding-right: 32px;
           // flex: 1 0 100%;
   
           // display: flex;
           // align-items: center;
       }
       &__description {
        // position: absolute;
         top: 140px;
        // left: 0px;
    }
        &__line {
            // position: absolute;
            // left: 0;
            bottom: 50px;
            // width: 1133px;
            width: 90%;
        }
    }
}