//
.recommend2 {
    height: 456px;
    background: #F8F8F8;

    display: flex;
    justify-content: center;
    align-items: center;

    &__page{
        max-width: 1200px;
        flex: 1 0 100%;
    }

    &__text {
        width: 610px;

        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
        letter-spacing: 0.01em;
        color: #38414D;
    }

    &__line {
        width: 620px;

        padding-top: 40px;
    }
}

//768 * 1919
@media (max-width: 1199px) {
    .recommend2 {
        height: 420px;

        &__page{
            max-width: 1090px;
            // flex: 1 0 100%;
        }

    }
}

//768 * 1919
@media (max-width: 1199px) {
    .recommend2 {
        height: 420px;

        &__page{
            max-width: 668px;
            // flex: 1 0 100%;
        }

        &__text {
            width: 435px;
    
            // font-family: 'Noto Sans';
            // font-style: normal;
            // font-weight: 400;
            font-size: 15px;
            line-height: 160%;
            // letter-spacing: 0.01em;
            // color: #38414D;
        }

        &__line {
            width: 435px;
    
            // padding-top: 40px;
        }
    }
}

//768 * 1023
// @media(max-width: 1023px) {}

//320 * 767
@media (max-width: 767px) {
    .recommend2 {
        height: 410px;

        &__page{
            max-width: 256px;
            max-width: 100%;
            width: 100%;
            padding-left: 32px;
            padding-right: 32px;
            // flex: 1 0 100%;
        }

        &__text {
            width: 100%;
    
            // font-family: 'Noto Sans';
            // font-style: normal;
            // font-weight: 400;
            font-size: 14px;
            line-height: 155%;
            // letter-spacing: 0.01em;
            // color: #38414D;
        }

        &__line {
            width: 100%;
    
            // padding-top: 40px;
        }
    }
}