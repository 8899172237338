// 
.modal {
    z-index: 100000;
    position: fixed;
    background-color: aqua;
    background: rgba(0, 0, 0, 0.8);

    height: 100vh;
    width: 100vw;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    &__page {
        background-color: white;
        border-radius: 5px;

        width: 500px;
        padding: 40px;

        position: relative;
        top: -10%;
    }

    &__heading {
        display: flex;
        justify-content: center;

        h3 {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 1.7rem;
            color: #0F2538;
        }
    }

    &__content {
        display: flex;
        justify-content: center;
        align-items: center;

        padding-top: 15px;

        &__img {
            img {
                height: 100%;
                width: auto;
            }
        }

        &__text {
            p {
                font-family: 'Noto Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 1rem;
                color: #38414D;
            }
        }
    }
}

//1024 * 1919
@media (max-width: 1919px) {}

//768 * 1023
@media(max-width: 1023px) {}

//320 * 767
@media (max-width: 767px) {
    .modal {
        &__page {
            // background-color: white;
            // border-radius: 5px;

            // width: 500px;
            // padding: 40px;

            margin: 0 10px;
        }
    }

}