// 
.galleryfix {
    max-width: 1920px;

    img {
        display: block;

        position: absolute;
        bottom: 0;
    }

    &__top {
        display: flex;
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
    }

    &__photo1 {
        // background-color: aqua;
        width: 790px;
        height: 822px;

        margin-right: 30px;

        overflow: hidden;

        position: relative;

        img {
            height: 100%;
            width: auto;
        }
    }

    &__photo2,
    &__photo3 {
        // background-color: aqua;
        width: 380px;
        height: 396px;

        overflow: hidden;

        position: relative;

        display: flex;
        justify-content: center;
        img {
            height: 100%;
            width: auto;
        }
    }

    &__photo2 {
        margin-bottom: 30px;
    }

    &__photo3 {}

    &__photo4,
    &__photo5 {
        background-color: aqua;
        width: 585px;
        height: 395px;

        margin-top: 30px;

        overflow: hidden;

        position: relative;

        img {
            height: 100%;
            width: auto;
        }
    }

    &__photo4 {
        margin-right: 30px;
    }

    &__photo5 {}
}

//1024 * 1919
@media (max-width: 1199px) {
    .galleryfix {

        // max-width: 924px;
        &__photo1 {
            // background-color: aqua;
            width: 607px;
            height: 628px;

            // margin-right: 30px;

            // overflow: hidden;

            // position: relative;
            img {
                // height: auto;
                // width: 100%;
            }
        }

        &__photo2,
        &__photo3 {
            // background-color: aqua;
            width: 287px;
            height: 299px;

            // overflow: hidden;

            // position: relative;
        }

        &__photo4,
        &__photo5 {
            // background-color: aqua;
            width: 447px;
            height: 302px;

            // margin-top: 30px;

            // overflow: hidden;

            // position: relative;

            img {
                // height: auto;
                // width: 100%;
            }
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .galleryfix {

        // max-width: 924px;
        &__photo1 {
            // background-color: aqua;
            width: 435px;
            height: 536px;

            // margin-right: 30px;

            // overflow: hidden;

            // position: relative;
            img {
                // height: auto;
                // width: 100%;

                height: 100%;
                width: auto;
            }
        }

        &__photo2,
        &__photo3 {
            // background-color: aqua;
            width: 203px;
            height: 253px;

            // overflow: hidden;

            // position: relative;
        }

        &__photo4,
        &__photo5 {
            // background-color: aqua;
            width: 319px;
            height: 223px;

            // margin-top: 30px;

            // overflow: hidden;

            // position: relative;

            img {
                // height: auto;
                // width: 100%;

                height: 100%;
                width: auto;
            }
        }
    }

}

//320 * 767
@media (max-width: 767px) {}