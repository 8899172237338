// 
.vacancy2 {
    display: flex;
    justify-content: center;

    background: #F8F8F8;

    &__page {
        max-width: 1200px;
        flex: 1 0 100%;

        padding: 160px 0;
    }

    &__text1 {
        width: 688px;

        p {
            font-family: 'OpiumNewC';
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 160%;
            letter-spacing: 0.03em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            color: #0F2538;
        }
    }

    &__text2 {
        width: 688px;

        padding-top: 40px;

        P {
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 180%;
            letter-spacing: 0.01em;
            color: #38414D;
        }
    }
}

//1024 * 1919
@media (max-width: 1199px) {
    .vacancy2 {
        &__page {
            max-width: 924px;
            // flex: 1 0 100%;

            // padding: 160px 0;
        }

        &__text1 {
            width: 527px;

            p {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 18px;
                // line-height: 160%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__text2 {
            width: 527px;

            // padding-top: 40px;
            P {
                //     font-family: 'Noto Sans';
                //     font-style: normal;
                //     font-weight: 400;
                //     font-size: 16px;
                //     line-height: 180%;
                //     letter-spacing: 0.01em;
                //     color: #38414D;
            }
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .vacancy2 {
        &__page {
            max-width: 668px;
            // flex: 1 0 100%;

            padding: 140px 0;
        }

        &__text1 {
            width: 552px;

            p {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 17px;
                // line-height: 160%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__text2 {
            width: 552px;

            padding-top: 48px;

            P {
                //     font-family: 'Noto Sans';
                //     font-style: normal;
                //     font-weight: 400;
                font-size: 15px;
                //     line-height: 180%;
                //     letter-spacing: 0.01em;
                //     color: #38414D;
            }
        }

    }
}

//320 * 767
@media (max-width: 767px) {
    .vacancy2 {
        &__page {
            max-width: 256px;
            max-width: 100%;

            padding: 100px 0;
            padding-left: 32px;
            padding-right: 32px;
            // flex: 1 0 100%;

            
        }

        &__text1 {
            width: 100%;

            p {
                // font-family: 'OpiumNewC';
                // font-style: normal;
                // font-weight: 400;
                font-size: 16px;
                // line-height: 160%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #0F2538;
            }
        }

        &__text2 {
            width: 100%;

            padding-top: 48px;

            P {
                //     font-family: 'Noto Sans';
                //     font-style: normal;
                //     font-weight: 400;
                font-size: 14px;
                //     line-height: 180%;
                //     letter-spacing: 0.01em;
                //     color: #38414D;
            }
        }

    }
}