.partners6 {
    height: 614px;

    display: flex;
    justify-content: center;


    &__page {
        max-width: 1200px;
        flex: 1 0 100%;
    }

    h3 {
        padding-top: 140px;

        width: 688px;

        font-family: 'OpiumNewC';
        font-style: normal;
        font-weight: 400;
        font-size: 44px;
        line-height: 135%;
        letter-spacing: 0.02em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
        color: #0F2538;
    }

    &__content {
        padding-top: 60px;

        display: flex;
        justify-content: space-between;
    }

    &__text {
        width: 585px;
        height: 100%;
        padding-bottom: 50px;

        border-bottom: 1px solid;
        border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 100 100;

        p {
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 180%;
            letter-spacing: 0.01em;
            color: #38414D;
        }
    }

    &__text2 {
        z-index: 1;
        display: flex;
        flex-direction: column;
    }

    &__quote {
        width: 200px;

        p {
            font-family: 'Noto Sans';
            font-style: italic;
            font-weight: 400;
            font-size: 24px;
            line-height: 122%;
            letter-spacing: 0.03em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            color: #38414D;
        }

    }

    &__button{
        z-index: 2;
        padding-top: 39px;
    }
}


//1024 * 1919
@media (max-width: 1199px) {
    .partners6 {
        height: 574px;

        &__page {
            max-width: 925px;
            // flex: 1 0 100%;
        }

        h3 {
            // padding-top: 140px;
    
            width: 450px;
    
            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 29px;
            // line-height: 135%;
            // letter-spacing: 0.02em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #0F2538;
        }

        &__text {
            width: 420px;
            // height: 100%;
            padding-bottom: 40px;
    
            // border-bottom: 1px solid;
            // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 100 100;
    
            p {
                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 16px;
                // line-height: 180%;
                // letter-spacing: 0.01em;
                // color: #38414D;
            }
        }

        &__quote {
            // width: 200px;
            padding-top: 20px;
    
            p {
                // font-family: 'Noto Sans';
                // font-style: italic;
                // font-weight: 400;
                font-size: 20px;
                // line-height: 122%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #38414D;
            }
    
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .partners6 {
        height: 520px;

        &__page {
            max-width: 667px;
            // flex: 1 0 100%;
        }

        h3 {
            // padding-top: 140px;
    
            width: 400px;
    
            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 25px;
            // line-height: 135%;
            // letter-spacing: 0.02em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #0F2538;
        }

        &__text {
            width: 330px;
            // height: 100%;
            // padding-bottom: 40px;
    
            // border-bottom: 1px solid;
            // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 100 100;
    
            p {
                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 15px;
                // line-height: 180%;
                // letter-spacing: 0.01em;
                // color: #38414D;
            }
        }

        &__quote {
            // width: 200px;
            padding-top: 60px;
    
            p {
                // font-family: 'Noto Sans';
                // font-style: italic;
                // font-weight: 400;
                font-size: 18px;
                // line-height: 122%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #38414D;
            }
    
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .partners6 {
        height: 650px;

        &__page {
            // max-width: 256px;
            max-width: 100%;

            width: 100%;
            padding-left: 32px;
            padding-right: 32px;


            // flex: 1 0 100%;
        }

        &__content {
            padding-top: 30px;
    
            // display: flex;
            // justify-content: space-between;
            flex-direction: column;
        }

        h3 {
            padding-top: 80px;
    
            // width: 256px;
            width: 100%;

            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 22px;
            // line-height: 135%;
            // letter-spacing: 0.02em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #0F2538;
        }

        &__text {
            // width: 256px;
            width: 100%;

            // height: 100%;
            padding-bottom: 30px;
    
            // border-bottom: 1px solid;
            // border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 100 100;
    
            p {
                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 14px;
                // line-height: 180%;
                // letter-spacing: 0.01em;
                // color: #38414D;
            }
        }

        &__quote {
            // width: 200px;
            padding-top: 50px;
    
            p {
                // font-family: 'Noto Sans';
                // font-style: italic;
                // font-weight: 400;
                font-size: 16px;
                // line-height: 122%;
                // letter-spacing: 0.03em;
                // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
                // color: #38414D;
            }
    
        }

        &__button{
            padding-top: 28px;
            padding-left: 20px;
        }
    }
}