.footer {
    background-color: #071424;

    display: flex;
    justify-content: center;

    &__page {
        max-width: 1600px;
        flex: 1 0 100%;

        padding: 0 50px 0;
    }

    &__block1 {
        display: flex;
        justify-content: space-between;

        padding-top: 80px;
    }

    &__logo {
        max-width: 160px;

        flex: 1 0 100%;

        position: relative;
        top: -17px;

        a {
            // width: 100%;
        }

        img {
            display: block;
            width: 100%;
        }
    }

    &__links {
        display: flex;
    }

    &__links1,
    &__links2,
    &__links3 {
        width: 200px;

        h3 {
            padding-bottom: 30px;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 900;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #F5F5F5;
        }

        a {
            display: block;
            text-decoration: none;
            padding-bottom: 15px;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #A0A8B3;

            transition: color 0.3s linear;
        }

        a:hover {
            color: #F5F5F5;
        }
    }

    &__phones {
        max-width: 30%;
        flex: 1 0 100%;

        display: flex;
        flex-direction: column;

        padding-left: 50px;
    }

    &__phone,
    &__mail {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        a {
            padding-bottom: 10px;
            text-decoration: none;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 95%;
            color: #F5F5F5;
        }

        span {
            padding-bottom: 25px;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 95%;
            color: #A0A8B3;
        }
    }

    &__block2 {
        height: 73px;

        margin-top: 20px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        border-top: solid 1px #A0A8B3;
        border-image: linear-gradient(to right, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.02)) 1 ;


        span,
        a {
            text-decoration: none;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 95%;
            color: #A0A8B3;
        }
    }

    &__copyright {}

    &__social {}

    &__agreement {
        a{
          transition: color 0.3s linear;  
        }
        
        a:hover {
            color: #F5F5F5;
        }
    }
}

//1699 * 1919
@media (max-width: 1919px) {
    .footer {
        &__logo {
            max-width: 142px;

            // flex: 1 0 100%;

            // position: relative;
            top: -10px;

            a {
                // width: 100%;
            }

            img {
                // display: block;
                // width: 100%;
            }
        }
    }
}

//1280 * 1699
@media (max-width: 1699px) {
    .footer {
        &__logo {
            max-width: 120px;

            // flex: 1 0 100%;

            // position: relative;
            // top: -17px;

            a {
                // width: 100%;
            }

            img {
                // display: block;
                // width: 100%;
            }
        }
    }
}

//1024 * 1279
@media (max-width: 1279px) {
    .footer {
        &__phones {
            max-width: 200px;
            // flex: 1 0 100%;

            // display: flex;
            // flex-direction: column;

            padding-left: 40px;
        }

        &__links1,
        &__links2,
        &__links3 {
            width: 170px;

            h3 {
                // padding-bottom: 30px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 900;
                // font-size: 14px;
                // line-height: 19px;
                // letter-spacing: 0.05em;
                // text-transform: uppercase;
                // color: #F5F5F5;
            }

            a {
                // display: block;
                // text-decoration: none;
                // padding-bottom: 15px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 16px;
                // line-height: 150%;
                // color: #A0A8B3;
            }

            a:hover {
                // color: #F5F5F5;
            }
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .footer {
        &__block1 {
            // display: flex;
            justify-content: flex-start;
            flex-direction: column;
            align-items: center;

            // padding-top: 80px;
        }

        &__links {
            // display: flex;
            flex-direction: column;
            padding-top: 50px;
        }

        &__links1,
        &__links2,
        &__links3 {
            //  width: 170px;

            h3 {
                // padding-bottom: 30px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 900;
                // font-size: 14px;
                // line-height: 19px;
                // letter-spacing: 0.05em;
                // text-transform: uppercase;
                // color: #F5F5F5;

                display: none;
            }

            a {
                // display: block;
                // text-decoration: none;
                // padding-bottom: 15px;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 16px;
                // line-height: 150%;
                // color: #A0A8B3;

                display: flex;
                justify-content: center;
            }

            a:hover {
                // color: #F5F5F5;
            }
        }

        &__phones {
            //max-width: 200px;
            // flex: 1 0 100%;

            // display: flex;
            // flex-direction: column;

            //padding-left: 40px;

            display: none;
        }

        &__block2 {
            // height: 73px;

            // display: flex;
            // justify-content: space-between;
            // align-items: center;

            // border-top: solid 1px #A0A8B3;

            // span,
            // a {
            //     text-decoration: none;

            //     font-family: 'Noto Sans';
            //     font-style: normal;
            //     font-weight: 400;
            //     font-size: 14px;
            //     line-height: 95%;
            //     color: #A0A8B3;
            // }
            margin-top: 40px;
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .footer {
        &__block2 {
            height: 110px;

            // display: flex;
            justify-content: flex-start;
            // align-items: center;

            // border-top: solid 1px #A0A8B3;

            span,
            a {
                //     text-decoration: none;

                //     font-family: 'Noto Sans';
                //     font-style: normal;
                //     font-weight: 400;
                font-size: 12px;
                // line-height: 1;
                text-align: center;
                //     color: #A0A8B3;
            }

            // margin-top: 40px;
            flex-direction: column;
        }

        &__copyright {
            padding-top: 30px;

            width: 150px;
            line-height: 180%;

            span {
                display: inline-block;
                line-height: 150%;
            }
        }

    }
}