//
.photolicense {
    height: 655px;
    width: 400px;

    overflow: hidden;

    display: flex;
    flex-direction: column;

    &__imgwrapper {
        cursor: pointer;
        height: 545px;
        width: 380px;
        border: 1px solid #f3f3f7;

        box-shadow: 0px 4px 10px 0px rgb(38 38 50 / 18%);

        display: flex;
        justify-content: center;
        align-items: center;

        overflow: hidden;

        &:hover {
            border: 1px solid #e8e8f0;
            box-shadow: 0px 4px 6px 0px rgb(38 38 50 / 18%);
        }

        align-self: center;
        margin-top: 10px;
        position: relative;
    }

    &__img {
        display: block;
        width: auto;
        height: 100%;
    }

    &__name {
        display: block;

        padding-top: 30px;
        padding-left: 10px;

        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #0F2538;
    }

    &__position {
        display: block;

        padding-top: 10px;
        padding-left: 10px;

        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #38414D;
    }

    &__zoom{
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.65);

        position: absolute;
        z-index: 10000;

        transition: opacity  0.3s linear;

        display: flex;
        justify-content: center;
        align-items: center;

        &_active{
            width: 70px;
            height: 70px;
            opacity: 1;
        }
        &_passive{
            width: 0px;
            height: 0px;
            opacity: 0;
        }
    }

    &__plus{
        display: flex;
        justify-content: center;
        align-items: center;

        color: white;
        font-size: 1.2rem;

        position: relative;
        // left: 5%;
        // top: 5%;

        &_active{
            width: 30px;
            height: 30px;
        }
        &_passive{
            width: 0px;
            height: 0px;
            display: none;
        }
    }
}

//1024 * 1919
// @media (max-width: 1919px) {
// .photomanagers {
//     height: 410px;
//     width: 200px;

//     &__imgwrapper {
//         height: 270px;
//         width: 200px;
//     }

//     &__name {
//         font-size: 16px;
//     }

//     &__position {
//         font-size: 14px;
//     }
// }
// }

//768 * 1023
@media(max-width: 1919px) {
    .photolicense {
        height: 545px;
        width: 319px;

        &__imgwrapper {
            height: 440px;
            width: 299px;
        }

        &__name {
            padding-top: 24px;
            font-size: 16px;
        }

        &__position {
            padding-top: 8px;
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .photolicense {
        height: 432px;
        width: 256px;

        &__imgwrapper {
            height: 340px;
            width: 236px;
        }

        &__name {
            font-size: 16px;
        }

        &__position {
            font-size: 14px;
        }
    }
}