// 
.partners5 {
    display: flex;
    justify-content: center;

    &__page {
        max-width: 1200px;
        flex: 1 0 100%;
    }

    h3 {
        padding-top: 140px;

        font-family: 'OpiumNewC';
        font-style: normal;
        font-weight: 400;
        font-size: 44px;
        line-height: 135%;
        letter-spacing: 0.02em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
        color: #0F2538;
    }

    ul {
        list-style: none;
        padding-top: 100px;
        padding-bottom: 140px;

        li {
            cursor: pointer;

            border-top: 1px solid;
            border-image: linear-gradient(to right, rgba(165, 173, 184, 0.4), rgba(165, 173, 184, 0.05)) 1;

            padding: 20px 0;
        }

        li:last-child {
            border-bottom: 1px solid;
        }
    }

    &__liopen {


        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 46px;

        span {
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 130%;
            color: #0F2538;
        }

        img {
            transform: rotate(0deg);
            transition: transform 0.2s linear 0s;
        }

        &_active {
            img {
                transform: rotate(180deg);
            }
        }
    }

    &__liclose {
        display: flex;
        flex-direction: column;

        position: relative;

        display: none;

        &_active {
            display: flex;
        }

        &_point {
            padding-bottom: 10px;
            padding-top: 10px;

            &::before {
                content: '.';
                font-size: 40px;
                position: relative;
                top: -3px;
                padding-right: 10px;
                line-height: 0;
            }
        }

        &_endline {
            padding: 10px 0 10px;
        }

        span {
            display: block;

            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #38414D;
        }
    }

    &__button {
        position: absolute;
        bottom: 10px;
        right: 100px;

        span {
            color: #A0A8B3;
        }
    }
}


//1024 * 1919
@media (max-width: 1199px) {
    .partners5 {
        &__page {
            max-width: 924px;
            // flex: 1 0 100%;
        }

        h3 {
            // padding-top: 140px;

            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 29px;
            // line-height: 135%;
            // letter-spacing: 0.02em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #0F2538;
        }

        &__liclose {
            // display: flex;
            // flex-direction: column;

            // position: relative;

            // display: none;

            &_active {
                // display: flex;
            }

            &_point {
                // padding-bottom: 10px;

                &::before {
                    // content: '.';
                    // font-size: 40px;
                    // position: relative;
                    // top: -3px;
                    // padding-right: 10px;
                    // line-height: 0;
                }
            }

            &_endline {
                // padding: 0px 0 10px;
                width: 65%;
            }

            span {
                // display: block;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                // font-size: 16px;
                // line-height: 150%;
                // color: #38414D;
            }
        }
    }
}

//768 * 1023
@media(max-width: 1023px) {
    .partners5 {
        &__page {
            max-width: 667px;
            // flex: 1 0 100%;
        }

        h3 {
            // padding-top: 140px;

            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 24px;
            // line-height: 135%;
            // letter-spacing: 0.02em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #0F2538;
        }

        &__liopen {
            // display: flex;
            // justify-content: space-between;
            // align-items: center;

            // height: 46px;

            span {
                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 18px;
                // line-height: 130%;
                // color: #0F2538;
            }

            img {
                // transform: rotate(0deg);
                // transition: transform 0.1s linear 0s;
            }

            &_active {
                img {
                    // transform: rotate(180deg);
                }
            }
        }

        &__liclose {
            // display: flex;
            // flex-direction: column;

            // position: relative;

            // display: none;

            &_active {
                // display: flex;
            }

            &_point {
                // padding-bottom: 10px;

                &::before {
                    // content: '.';
                    // font-size: 40px;
                    // position: relative;
                    // top: -3px;
                    // padding-right: 10px;
                    // line-height: 0;
                }
            }

            &_endline {
                // padding: 0px 0 10px;
                width: 80%;
            }

            span {
                // display: block;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 15px;
                // line-height: 150%;
                // color: #38414D;
            }
        }

        &__button {
            position: static;
            // bottom: 10px;
            // right: 100px;

            align-self: flex-end;
            padding-right: 100px;

            span {
                // color: #A0A8B3;
            }
        }
    }
}

//320 * 767
@media (max-width: 767px) {
    .partners5 {
        justify-content: flex-start;
        width: 100%;

        &__page {
            max-width: 100%;

            width: 100%;
            padding-left: 32px;
            padding-right: 32px;

            // flex: 1 0 100%;
        }

        ul {
            padding-top: 70px;
            padding-bottom: 50px;
        }

        h3 {
            padding-top: 40px;

            // font-family: 'OpiumNewC';
            // font-style: normal;
            // font-weight: 400;
            font-size: 22px;
            // line-height: 135%;
            // letter-spacing: 0.02em;
            // font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
            // color: #0F2538;
        }

        &__liclose {
            // display: flex;
            // flex-direction: column;

            // position: relative;

            // display: none;

            &_active {
                // display: flex;
            }

            &_point {
                // padding-bottom: 10px;

                width: 80%;

                &::before {
                    // content: '.';
                    // font-size: 40px;
                    // position: relative;
                    // top: -3px;
                    // padding-right: 10px;
                    // line-height: 0;
                }
            }

            &_endline {
                // padding: 0px 0 10px;
                width: 80%;
            }

            span {
                // display: block;

                // font-family: 'Noto Sans';
                // font-style: normal;
                // font-weight: 400;
                font-size: 14px;
                // line-height: 150%;
                // color: #38414D;
            }
        }

        &__button {
            // position: static;
            // bottom: 10px;
            // right: 100px;

            // align-self: flex-end;
            padding-right: 40px;

            span {
                // color: #A0A8B3;
            }
        }
    }
}